import Dashboard from 'components/Icon/Dashboard';
import {
    HiCash,
    HiClipboardList,
    HiOutlineCash,
    HiOutlineCode,
} from 'react-icons/hi';
import {FiHelpCircle} from 'react-icons/fi';
import {HiOutlineCog, HiOutlineDocumentReport} from 'react-icons/hi';
import {LuComponent} from 'react-icons/lu';
import {
    HiCog8Tooth,
    HiCurrencyDollar,
    HiGiftTop,
    HiHome,
    HiCreditCard,
    HiIdentification,
    HiOutlineUserGroup,
    HiShieldCheck,
    HiTicket,
    HiUserGroup,
} from 'react-icons/hi2';
import Convert from 'components/Icon/Convert';
import {AiFillAppstore} from 'react-icons/ai';
import {IoInformationCircle, IoShield} from 'react-icons/io5';
import {BsCurrencyExchange} from 'react-icons/bs';
import {IoNewspaperSharp} from 'react-icons/io5';
import {BiSupport} from 'react-icons/bi';
import {IoMdHelpCircle} from 'react-icons/io';
import {BsPersonBadge} from 'react-icons/bs';
import {CgToolbox} from 'react-icons/cg';
import {FaHireAHelper} from 'react-icons/fa';
import {IoHelpBuoy} from 'react-icons/io5';
import {RiFundsBoxFill} from 'react-icons/ri';
import {MdAccountBalance} from 'react-icons/md';
import {FaInfoCircle} from 'react-icons/fa';
import Sublevels from '../page/app/IB/Sublevels';
import {isDev} from 'config/app';
import {CgShutterstock} from 'react-icons/cg';

// import {MdDashboard as Dashboard} from 'react-icons/md';
function path(root, sublink) {
    return `${root}${sublink}`;
}

export const ROOTS_AUTH = '/auth';
export const ROOTS_APP = '/app';
export const ROOTS_ADMIN = '/admin';

export const ROOTS_DEV = '/dev';

export const devPaths = {
    root: ROOTS_DEV,
    apis: path(ROOTS_DEV, '/api-explorer'),
};

export const authPaths = {
    //auth
    login: path(ROOTS_AUTH, '/login'),
    forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    signUp: path(ROOTS_AUTH, '/sign-up'),
    signUpVerify: path(ROOTS_AUTH, '/sign-up-verify'),
    logout: path(ROOTS_AUTH, '/logout'),
    requiredUpdatePassOtp: path(ROOTS_AUTH, '/required-update-pass-otp'),
};

export const paths = {
    root: ROOTS_APP,
    redirector: path('', '/redirector'),
    maintenance: path('', '/maintenance'),
    //app
    dashboard: path(ROOTS_APP, '/dashboard'),
    profile: path(ROOTS_APP, '/profile'),
    notificationSetting: path(ROOTS_APP, '/profile/notification'),
    profileKycReview: path(ROOTS_APP, '/profile/kyc'),
    profileCryptoWallet: path(ROOTS_APP, '/profile/crypto-wallet'),
    preference: path(ROOTS_APP, '/profile/preference'),
    security: path(ROOTS_APP, '/profile/security'),
    settings: path(ROOTS_APP, '/settings'),
    components: path(ROOTS_APP, '/components'),
    //kyc paths
    kyc: path(ROOTS_APP, '/kyc'),
    kyb: path(ROOTS_APP, '/kyb'),
    //help center
    helpCenter: path(ROOTS_APP, '/help-center'),
    support: path(ROOTS_APP, '/help-center/support'),
    supportDetail: (id) => path(ROOTS_APP, `/help-center/support/${id}`),
    newSupport: path(ROOTS_APP, '/help-center/support/newSupport'),
    QAndA: path(ROOTS_APP, '/help-center/q-and-a'),

    //api
    apiAccess: path(ROOTS_APP, '/apis'),
    apis: path(ROOTS_APP, '/apis/api-explorer'),
    apiLog: path(ROOTS_APP, '/apis/api-log'),
    apiKey: path(ROOTS_APP, '/apis/api-key'),

    //referral
    referralRoot: path(ROOTS_APP, '/referral'),
    referees: path(ROOTS_APP, '/referral/referees'),
    referralCommission: path(ROOTS_APP, '/referral/commission'),

    //stock
    stock: path(ROOTS_APP, '/stock'),
    shareAccount: path(ROOTS_APP, '/stock/shareAccount'),
    stockAccount: path(ROOTS_APP, '/stock/account'),
    stockPosition: path(ROOTS_APP, '/stock/positions'),
    stockHistory: path(ROOTS_APP, '/stock/history'),
    stockTransaction: path(ROOTS_APP, '/stock/transactions'),
    stockStatement: path(ROOTS_APP, '/stock/statements'),
    stockSubscriptions: path(ROOTS_APP, '/stock/live-data-subscriptions'),
    stockProducts: path(ROOTS_APP, '/stock/stock-products'),

    //debitCard
    debitCard: path(ROOTS_APP, '/debitCard'),
    debitCard: path(ROOTS_APP, '/debitCard/debitCard'),
    application: path(ROOTS_APP, '/debitCard/application'),
    feeAndTopups: path(ROOTS_APP, '/debitCard/feeAndTopups'),

    //funding
    funding: path(ROOTS_APP, '/funding'),
    fundingSummary: path(ROOTS_APP, '/funding/funding-summary'),
    deposits: path(ROOTS_APP, '/funding/deposits'),
    newDeposit: path(ROOTS_APP, '/funding/newDeposit'),
    withdrawals: path(ROOTS_APP, '/funding/withdrawals'),
    creatWithdrawal: path(ROOTS_APP, '/funding/withdrawals/creatWithdrawal'),
    withdrawalSuccess: path(
        ROOTS_APP,
        '/funding/withdrawals/withdrawalSuccess',
    ),
    transfers: path(ROOTS_APP, '/funding/transfers'),
    creatTransfer: path(ROOTS_APP, '/funding/transfers/creatTransfer'),
    transferSuccess: path(ROOTS_APP, '/funding/transfers/transferSuccess'),
    commissions: path(ROOTS_APP, '/funding/commissions'),
    moneyMove: path(ROOTS_APP, '/funding/moneyMove'),
    withdrawalAccounts: path(ROOTS_APP, '/funding/funding-withdrawal-accounts'),
    statements: path(ROOTS_APP, '/funding/statements'),

    channelDepositComplete: path(
        ROOTS_APP,
        '/funding/deposits/channel-deposit-complete',
    ),
    channelDepositCancel: path(
        ROOTS_APP,
        '/funding/deposits/channel-deposit-cancel',
    ),

    //accounts
    accounts: path(ROOTS_APP, '/mtAccounts'),
    accountsWithTypeId: (type, id) =>
        path(ROOTS_APP, `/mtAccounts?type=${type}&accountId=${id}`),

    //broker
    ibAgent: path(ROOTS_APP, '/ib-agent'),
    wiredAccounts: path(ROOTS_APP, '/ib-agent/wired-accounts'),
    ibOverview: path(ROOTS_APP, '/ib-agent/ib-overview'),
    ibSublevels: path(ROOTS_APP, '/ib-agent/ib-sublevels'),
    ibReports: path(ROOTS_APP, '/ib-agent/ib-reports'),
    pendingInvitations: path(ROOTS_APP, '/ib-agent/pending-invitations'),
    // ib
    ib: path(ROOTS_APP, '/ib-agent'),

    newsPage: path(ROOTS_APP, '/newsPage'),
    //news
    news: path(ROOTS_APP, '/newsPage/news'),
    //news
    announcements: path(ROOTS_APP, '/newsPage/announcements'),

    //tools
    tools: path(ROOTS_APP, '/tools'),
    downloads: path(ROOTS_APP, '/downloads'),

    //help
    help: path(ROOTS_APP, '/help'),

    //About us
    aboutUs: path(ROOTS_APP, '/aboutUs'),

    //About us
    notification: path(ROOTS_APP, '/notification'),
};

export const adminPaths = {
    root: ROOTS_ADMIN,
    dashboard: path(ROOTS_ADMIN, '/dashboard'),
    opensearchDashboard: path(ROOTS_ADMIN, '/opensearch-dashboard'),

    //profile
    profile: path(ROOTS_ADMIN, '/profile'),
    notificationSetting: path(ROOTS_ADMIN, '/profile/notification'),
    preference: path(ROOTS_ADMIN, '/profile/preference'),
    security: path(ROOTS_ADMIN, '/profile/security'),

    //clients
    clientsHome: path(ROOTS_ADMIN, '/clients'),
    clients: path(ROOTS_ADMIN, '/clients/clients'),
    clientDetail: (id) => path(ROOTS_ADMIN, `/clients/clients/${id}`),
    clientDetailWithTab: (id, tab) =>
        path(ROOTS_ADMIN, `/clients/clients/${id}?tab=${tab}`),
    404: '/404',
    403: '/403',

    // management-report
    managementReport:{
        'pnl-closed': path(ROOTS_ADMIN, '/analytics/closedtrades/closed-pnl'),
        'closed-pnl': path(ROOTS_ADMIN, '/analytics/closedtrades/closed-pnl'),
        pnl: path(ROOTS_ADMIN, '/analytics/closedtrades/closed-pnl'),

        // closed pnl
        bySymbol: path(ROOTS_ADMIN, '/analytics/closedtrades/pnl/symbol'),
        byCurrencies: path(ROOTS_ADMIN, '/analytics/closedtrades/pnl/currencies'),
        byGroups: path(ROOTS_ADMIN, '/analytics/closedtrades/pnl/groups'),
        byBooks: path(ROOTS_ADMIN, '/analytics/closedtrades/pnl/books'),
        byTop: path(ROOTS_ADMIN, '/analytics/closedtrades/pnl/top'),
        byTrades: path(ROOTS_ADMIN, '/analytics/closedtrades/pnl/trades'),
        byAccount: path(ROOTS_ADMIN, '/analytics/closedtrades/pnl/accounts'),
        byServer: path(ROOTS_ADMIN, '/analytics/closedtrades/pnl/servers'),

        'lots-closed': path(ROOTS_ADMIN, '/analytics/closedtrades/lots'),
        lots: path(ROOTS_ADMIN, '/analytics/closedtrades/lots'),
        'trades-closed': path(ROOTS_ADMIN, '/analytics/closedtrades/trades'),
        trades: path(ROOTS_ADMIN, '/analytics/closedtrades/trades'),

        index: path(ROOTS_ADMIN, '/analytics/closedtrades'),
    },


    clientsLeads: path(ROOTS_ADMIN, '/clients/clients-leads'),
    clientsAccounts: path(ROOTS_ADMIN, '/clients/clients-accounts'),
    clientsRewireAccounts: path(ROOTS_ADMIN, '/clients/clients-rewire-accounts'),
    clientsPammAccounts: path(ROOTS_ADMIN, '/clients/clients-pamm-accounts'),
    clientsCCSubAccounts: path(ROOTS_ADMIN, '/clients/clients-cc-sub-accounts'),
    clientsIbAdmin: path(ROOTS_ADMIN, '/clients/clients-ib-admin'),
    managerSales: path(ROOTS_ADMIN, '/clients/manager-sales'),
    managerSupports: path(ROOTS_ADMIN, '/clients/manager-supports'),
    clientWithdrawalBanks: path(
        ROOTS_ADMIN,
        '/clients/client-withdrawal-banks',
    ),
    clientWithdrawalWallets: path(
        ROOTS_ADMIN,
        '/clients/client-withdrawal-wallets',
    ),
    clientDepositWallets: path(ROOTS_ADMIN, '/clients/client-deposit-wallets'),
    clientActivities: path(ROOTS_ADMIN, '/clients/client-activities'),
    demoAccounts: path(ROOTS_ADMIN, '/clients/demo-accounts'),
    debitCards: {
        index: path(ROOTS_ADMIN, '/clients/debit-cards'),
        list: path(ROOTS_ADMIN, '/clients/debit-cards/debit-list'),
        request: path(ROOTS_ADMIN, '/clients/debit-cards/debit-request'),
        feeAndTopUps: path(ROOTS_ADMIN, '/clients/debit-cards/debit-fee-top-up'),
        transaction: path(ROOTS_ADMIN, '/clients/debit-cards/debit-transaction'),
        setting: {
            index: path(ROOTS_ADMIN, '/platform-settings/debit-card'),
            type: path(ROOTS_ADMIN, '/platform-settings/debit-card/debit-type'),
            autoApproval: path(ROOTS_ADMIN, '/platform-settings/debit-card/debit-auto-approval'),
            excludeRegions: path(ROOTS_ADMIN, '/platform-settings/debit-card/debit-exclude-region'),
        }
    },

    stocks: path(ROOTS_ADMIN, '/stocks'),
    stockAccounts: path(ROOTS_ADMIN, '/stocks/accounts'),
    stockTransactions: path(ROOTS_ADMIN, '/stocks/transactions'),
    stockPositions: path(ROOTS_ADMIN, '/stocks/positions'),
    stockProducts: path(ROOTS_ADMIN, '/stocks/stock-products'),
    stockRequests: path(ROOTS_ADMIN, '/stocks/requests'),
    stockRequestSummary: path(ROOTS_ADMIN, '/stocks/requests/summary'),
    stockRequestAccount: path(ROOTS_ADMIN, '/stocks/requests/accounts'),
    stockRequestDeposits: path(ROOTS_ADMIN, '/stocks/requests/deposits'),
    stockRequestWithdrawals: path(ROOTS_ADMIN, '/stocks/requests/withdrawals'),
    stockRequestTransfer: path(ROOTS_ADMIN, '/stocks/requests/transfers'),
    stockTaskboard: path(ROOTS_ADMIN, '/stocks/taskboard'),
    stockTaskboardDeposits: path(
        ROOTS_ADMIN,
        '/stocks/taskboard/taskboard-deposits',
    ),
    stockTaskboardWithdrawals: path(
        ROOTS_ADMIN,
        '/stocks/taskboard/taskboard-withdrawals',
    ),
    stockTaskboardTransfer: path(
        ROOTS_ADMIN,
        '/stocks/taskboard/taskboard-transfer',
    ),
    myStockTasks: path(ROOTS_ADMIN, '/stocks/my-tasks'),

    // platform settings
    platformSettings: path(ROOTS_ADMIN, '/platform-settings'),

    //currency
    currencies: path(ROOTS_ADMIN, '/platform-settings/currencies'),
    currenciesPlatform: path(
        ROOTS_ADMIN,
        '/platform-settings/currencies/currencies-platform',
    ),
    currenciesAccounts: path(
        ROOTS_ADMIN,
        '/platform-settings/currencies/currencies-accounts',
    ),
    currenciesDefination: path(
        ROOTS_ADMIN,
        '/platform-settings/currencies/currencies-defination',
    ),
    currenciesWithdrawal: path(
        ROOTS_ADMIN,
        '/platform-settings/currencies/currencies-withdrawal',
    ),
    currenciesServiceFee: path(
        ROOTS_ADMIN,
        '/platform-settings/currencies/currencies-service-fee',
    ),

    //risk level
    riskLevel: path(ROOTS_ADMIN, '/platform-settings/risk-level'),
    // regulation region
    regulationRegion: path(ROOTS_ADMIN, '/platform-settings/regulation-region'),
    regulation: path(
        ROOTS_ADMIN,
        '/platform-settings/regulation-region/regulation-region',
    ),
    restrictedRegion: path(
        ROOTS_ADMIN,
        '/platform-settings/regulation-region/restricted-region',
    ),

    //System products
    systemProducts: path(ROOTS_ADMIN, '/platform-settings/system-products'),

    //metadata
    metadata: path(ROOTS_ADMIN, '/platform-settings/metadata'),
    metadataCategory: path(
        ROOTS_ADMIN,
        '/platform-settings/metadata/metadata-category',
    ),
    metadataEdit: path(
        ROOTS_ADMIN,
        '/platform-settings/metadata/metadata-edit',
    ),

    // regulation: path(ROOTS_ADMIN, '/platform-settings/metadata/regulation'),
    // otherMetadata: path(
    //     ROOTS_ADMIN,
    //     '/platform-settings/metadata/other-metadata',
    // ),
    //payment gateway
    paymentChannels: path(ROOTS_ADMIN, '/platform-settings/payment-channels'),
    paymentGateway: path(
        ROOTS_ADMIN,
        '/platform-settings/payment-channels/payment-gateway',
    ),
    blockchainGateway: path(
        ROOTS_ADMIN,
        '/platform-settings/payment-channels/blockchain',
    ),
    commonGateway: path(
        ROOTS_ADMIN,
        '/platform-settings/payment-channels/common-gateway',
    ),

    //mt4/5
    mt45Settings: path(ROOTS_ADMIN, '/platform-settings/mt4-5-settings'),
    mt4Settings: path(
        ROOTS_ADMIN,
        '/platform-settings/mt4-5-settings/mt4-settings',
    ),
    mtSettings: path(
        ROOTS_ADMIN,
        '/platform-settings/mt4-5-settings/mt-settings',
    ),
    mt4DemoSettings: path(
        ROOTS_ADMIN,
        '/platform-settings/mt4-5-settings/mt4-demo-settings',
    ),
    mt5Settings: path(
        ROOTS_ADMIN,
        '/platform-settings/mt4-5-settings/mt5-settings',
    ),

    //intergation
    integration: path(ROOTS_ADMIN, '/platform-settings/integration'),
    KYCProvider: path(
        ROOTS_ADMIN,
        '/platform-settings/integration/kyc-provider',
    ),
    SMSEmailProvider: path(
        ROOTS_ADMIN,
        '/platform-settings/integration/sms-email-provider',
    ),
    MessagerProvider: path(
        ROOTS_ADMIN,
        '/platform-settings/integration/messager-provider',
    ),
    // access control
    accessControl: path(ROOTS_ADMIN, '/platform-settings/access-control'),
    systemRoles: path(
        ROOTS_ADMIN,
        '/platform-settings/access-control/system-roles',
    ),
    systemRoleAccess: path(
        ROOTS_ADMIN,
        '/platform-settings/access-control/system-role-access',
    ),
    userAutherization: path(
        ROOTS_ADMIN,
        '/platform-settings/access-control/user-autherization',
    ),
    adminAccessLog: path(
        ROOTS_ADMIN,
        '/platform-settings/access-control/admin-access-log',
    ),
    clientAccessLog: path(
        ROOTS_ADMIN,
        '/platform-settings/access-control/client-access-log',
    ),

    //general settings
    generalSettings: path(ROOTS_ADMIN, '/platform-settings/general-settings'),
    systemSettings: path(
        ROOTS_ADMIN,
        '/platform-settings/general-settings/system-settings',
    ),
    clientPolicies: path(
        ROOTS_ADMIN,
        '/platform-settings/general-settings/client-policies',
    ),
    ibSettings: path(
        ROOTS_ADMIN,
        '/platform-settings/general-settings/ib-settings',
    ),

    // application management
    applicationManagement: path(ROOTS_ADMIN, '/application-management'),

    //api management
    apiManagement: path(ROOTS_ADMIN, '/application-management/api-management'),
    apiAutherization: path(
        ROOTS_ADMIN,
        '/application-management/api-management/api-autherization',
    ),
    apiAccessLog: path(
        ROOTS_ADMIN,
        '/application-management/api-management/api-access-log',
    ),
    apiErrorTracking: path(
        ROOTS_ADMIN,
        '/application-management/api-management/api-error-tracking',
    ),

    //system monitor
    systemMonitorHome: path(
        ROOTS_ADMIN,
        '/application-management/system-monitor',
    ),
    systemStatus: path(
        ROOTS_ADMIN,
        '/application-management/system-monitor/system-status',
    ),
    systemLog: path(
        ROOTS_ADMIN,
        '/application-management/system-monitor/system-log',
    ),
    systemAlert: path(
        ROOTS_ADMIN,
        '/application-management/system-monitor/system-alert',
    ),
    systemErrorTracking: path(
        ROOTS_ADMIN,
        '/application-management/system-monitor/system-error-tracking',
    ),
    systemScheduledTasks: path(
        ROOTS_ADMIN,
        '/application-management/system-monitor/system-scheduled-tasks',
    ),

    //security management
    securityManagement: path(
        ROOTS_ADMIN,
        '/application-management/security-management',
    ),
    ipAccessLog: path(
        ROOTS_ADMIN,
        '/application-management/security-management/ip-access-log',
    ),
    ipBlacklist: path(
        ROOTS_ADMIN,
        '/application-management/security-management/ip-blacklist',
    ),
    securityPolicy: path(
        ROOTS_ADMIN,
        '/application-management/security-management/security-policy',
    ),
    authRules: path(
        ROOTS_ADMIN,
        '/application-management/security-management/auth-rules',
    ),

    //bulk data
    bulkData: path(ROOTS_ADMIN, '/application-management/bulk-data'),
    clientsBulkData: path(
        ROOTS_ADMIN,
        '/application-management/bulk-data/clients-bulk-data',
    ),
    banksBulkData: path(
        ROOTS_ADMIN,
        '/application-management/bulk-data/banks-bulk-data',
    ),
    tradesBulkData: path(
        ROOTS_ADMIN,
        '/application-management/bulk-data/transactions-bulk-data',
    ),

    //finance
    finance: path(ROOTS_ADMIN, '/finance'),
    financeSummary: path(ROOTS_ADMIN, '/finance/finance-summary'),
    financeTransaction: path(ROOTS_ADMIN, '/finance/finance-transaction'),
    financeLedge: path(ROOTS_ADMIN, '/finance/finance-ledge'),
    financeExpenses: path(ROOTS_ADMIN, '/finance/finance-expenses'),
    financeRequests: path(ROOTS_ADMIN, '/finance/finance-requests'),

    manageReports: path(ROOTS_ADMIN, '/manage-reports'),
    netReports: path(ROOTS_ADMIN, '/manage-reports/net-reports'),
    clientReports: path(ROOTS_ADMIN, '/manage-reports/client-reports'),
    equityReports: path(
        ROOTS_ADMIN,
        '/manage-reports/equity-reports',
    ),
    manageCommissionReport: path(ROOTS_ADMIN, '/manage-reports/manage-commission-reports'),
    manageCommissionReportDetail: path(ROOTS_ADMIN, '/manage-reports/manage-commission-reports-detail'),


    //information
    informationHome: path(ROOTS_ADMIN, '/information'),
    informationAnnouncements: path(
        ROOTS_ADMIN,
        '/information/information-announcements',
    ),
    informationNewAnnouncement: path(
        ROOTS_ADMIN,
        '/information/information-announcements/new-announcement',
    ),
    informationAnnouncementDetail: (id) =>
        path(ROOTS_ADMIN, `/information/information-announcements/${id}`),
    informationNews: path(ROOTS_ADMIN, '/information/information-news'),
    informationHome: path(ROOTS_ADMIN, '/information/information-emails'),
    informationEmails: path(
        ROOTS_ADMIN,
        '/information/information-emails/information-email-list',
    ),
    informationNewEmail: path(
        ROOTS_ADMIN,
        '/information/information-emails/information-email-list/new-email',
    ),
    informationEmailDetail: (id) =>
        path(
            ROOTS_ADMIN,
            `/information/information-emails/information-email-list/${id}`,
        ),
    informationEmailTemplates: path(
        ROOTS_ADMIN,
        '/information/information-emails/information-email-template-list',
    ),
    informationNewEmailTemplate: path(
        ROOTS_ADMIN,
        '/information/information-emails/information-email-template-list/new-template',
    ),
    informationEmailTemplateDetail: (id) =>
        path(
            ROOTS_ADMIN,
            `/information/information-emails/information-email-template-list/${id}`,
        ),
    informationMessages: path(ROOTS_ADMIN, '/information/information-messages'),
    informationNewMessages: path(
        ROOTS_ADMIN,
        '/information/information-messages/new-message',
    ),
    informationMessageDetail: (id) =>
        path(ROOTS_ADMIN, `/information/information-messages/${id}`),
    informationAds: path(ROOTS_ADMIN, '/information/information-ads'),
    newAds: path(ROOTS_ADMIN, '/information/information-ads/createAds'),
    editAds: (id) => path(ROOTS_ADMIN, `/information/information-ads/${id}`),
    informationAccessSource: path(ROOTS_ADMIN, `/information/information-access-source`),
    informationCampaigns: path(ROOTS_ADMIN, `/information/information-access-source/campaigns`),
    informationCampaignsLogs: path(ROOTS_ADMIN, `/information/information-access-source/campaigns-logs`),
    informationCampaignsConvertedClients: path(ROOTS_ADMIN, `/information/information-access-source/converted-clients`),
    informationCampaignsRegisteredClients: path(ROOTS_ADMIN, `/information/information-access-source/registered-clients`),

    //supports
    supports: path(ROOTS_ADMIN, '/supports'),
    faq: path(ROOTS_ADMIN, '/supports/faq'),
    incidents: path(ROOTS_ADMIN, '/supports/incidents'),
    onlineSupport: path(ROOTS_ADMIN, '/supports/online-support'),
    tickets: path(ROOTS_ADMIN, '/supports/tickets'),
    ticketDetail: (id) => path(ROOTS_ADMIN, `/supports/tickets/${id}`),
    newTicket: path(ROOTS_ADMIN, '/supports/tickets/newTicket'),
    editFaq: path(ROOTS_ADMIN, '/supports/faq/editFaq'),
    newFaq: path(ROOTS_ADMIN, '/supports/faq/createFaq'),

    //transactions

    transactions: path(ROOTS_ADMIN, '/transactions'),
    transactionSummary: path(ROOTS_ADMIN, '/transactions/transaction-summary'),
    transactionDeposits: path(
        ROOTS_ADMIN,
        '/transactions/transaction-deposits',
    ),
    transactionWithdrawals: path(
        ROOTS_ADMIN,
        '/transactions/transaction-withdrawals',
    ),
    transactionTransfers: path(
        ROOTS_ADMIN,
        '/transactions/transaction-transfers',
    ),
    transactionCommissions: path(
        ROOTS_ADMIN,
        '/transactions/transaction-commissions',
    ),
    transactionAdjustments: path(
        ROOTS_ADMIN,
        '/transactions/transaction-adjustments',
    ),
    transactionMoneyMoves: path(
        ROOTS_ADMIN,
        '/transactions/transaction-money-moves',
    ),
    transactionHDWalletOperations: path(
        ROOTS_ADMIN,
        '/transactions/transaction-HDWallet-operations',
    ),

    //tasks
    tasks: path(ROOTS_ADMIN, '/tasks'),

    requests: path(ROOTS_ADMIN, '/tasks/requests'),
    requestSummary: path(ROOTS_ADMIN, '/tasks/requests/summary'),
    requestDeposits: path(ROOTS_ADMIN, '/tasks/requests/deposits'),
    requestWithdrawals: path(ROOTS_ADMIN, '/tasks/requests/withdrawals'),
    requestTransfer: path(ROOTS_ADMIN, '/tasks/requests/transfers'),
    requestLeverage: path(ROOTS_ADMIN, '/tasks/requests/leverages'),
    requestPassword: path(ROOTS_ADMIN, '/tasks/requests/passwords'),
    requestKyc: path(ROOTS_ADMIN, '/tasks/requests/kyc'),
    requestIB: path(ROOTS_ADMIN, '/tasks/requests/IB'),
    requestDebitCards: path(ROOTS_ADMIN, '/tasks/requests/debitCards'),
    requestWithdrawalAccount: path(ROOTS_ADMIN, '/tasks/requests/bankAccounts'),
    requestWithdrawalWallet: path(ROOTS_ADMIN, '/tasks/requests/wallets'),
    requestGeneral: path(ROOTS_ADMIN, '/tasks/requests/generals'),
    requestAccount: path(ROOTS_ADMIN, '/tasks/requests/accounts'),
    requestExchangeWithdrawal: path(
        ROOTS_ADMIN,
        '/tasks/requests/exchangeWithdrawal',
    ),

    myTasks: path(ROOTS_ADMIN, '/tasks/my-tasks'),

    //taskboard
    taskboard: path(ROOTS_ADMIN, '/tasks/taskboard'),
    taskboardKyc: path(ROOTS_ADMIN, '/tasks/taskboard/taskboard-kyc'),
    taskboardWithdrawalAccount: path(
        ROOTS_ADMIN,
        '/tasks/taskboard/taskboard-withdrawal-account',
    ),
    taskboardDeposits: path(ROOTS_ADMIN, '/tasks/taskboard/taskboard-deposits'),
    taskboardWithdrawals: path(
        ROOTS_ADMIN,
        '/tasks/taskboard/taskboard-withdrawals',
    ),
    taskboardTransfer: path(ROOTS_ADMIN, '/tasks/taskboard/taskboard-transfer'),

    taskboardLeverage: path(ROOTS_ADMIN, '/tasks/taskboard/taskboard-leverage'),

    taskboardPassword: path(ROOTS_ADMIN, '/tasks/taskboard/taskboard-password'),

    taskboardGeneral: path(ROOTS_ADMIN, '/tasks/taskboard/taskboard-general'),

    //risk management
    riskManagement: path(ROOTS_ADMIN, '/risk-management'),
    riskReport: path(ROOTS_ADMIN, '/risk-report'),

    //approvals
    approvals: path(ROOTS_ADMIN, '/risk-management/approvals'),
    approvalsMt45Accounts: path(
        ROOTS_ADMIN,
        '/risk-management/approvals/approvals-mt45-accounts',
    ),
    approvalsKyc: path(ROOTS_ADMIN, '/risk-management/approvals/approvals-kyc'),
    approvalsWithdrawalWallet: path(
        ROOTS_ADMIN,
        '/risk-management/approvals/approvals-withdrawal-wallet',
    ),
    approvalsExchangeWithdrawal: path(
        ROOTS_ADMIN,
        '/risk-management/approvals/approvals-exchange-withdrawal',
    ),
    approvalsLeverage: path(
        ROOTS_ADMIN,
        '/risk-management/approvals/approvals-leverage',
    ),
    approvalsIbApplications: path(
        ROOTS_ADMIN,
        '/risk-management/approvals/approvals-ib-applications',
    ),
    approvalsDebitCards: path(
        ROOTS_ADMIN,
        '/risk-management/approvals/approvals-debit-card',
    ),
    approvalsWithdrawalAccount: path(
        ROOTS_ADMIN,
        '/risk-management/approvals/approvals-withdrawal-account',
    ),
    approvalsFxWithdrawals: path(
        ROOTS_ADMIN,
        '/risk-management/approvals/approvals-fx-withdrawals',
    ),
    approvalsDebitCard: path(
        ROOTS_ADMIN,
        '/risk-management/approvals/approvals-debit-card',
    ),
    riskMtTradeMonitor: path(ROOTS_ADMIN, '/risk-report/mt-monitor'),
    riskMtTradeMonitorTotal: path(ROOTS_ADMIN, '/risk-report/mt-monitor/total'),
    riskMtTradeMonitorCurrency: path(
        ROOTS_ADMIN,
        '/risk-report/mt-monitor/currency',
    ),
    riskMtTradeMonitorSymbol: path(
        ROOTS_ADMIN,
        '/risk-report/mt-monitor/symbol',
    ),
    profitsAnalysis: path(ROOTS_ADMIN, '/risk-report/profits-analysis'),
    commissionAnalysis: path(ROOTS_ADMIN, '/risk-report/commission-analysis'),
    closedMtMonitor: path(ROOTS_ADMIN, '/risk-report/closed-monitor'),
    highGainAnalysis: path(ROOTS_ADMIN, '/risk-report/high-gain'),
    lowGainAnalysis: path(ROOTS_ADMIN, '/risk-report/low-gain'),
    profitOverCurrency: path(ROOTS_ADMIN, '/risk-report/profit-over-currency'),
    closedInSencond: path(ROOTS_ADMIN, '/risk-report/closed-in-second'),
    openInSencond: path(ROOTS_ADMIN, '/risk-report/open-in-second'),
    dailyProfitOver500USD: path(
        ROOTS_ADMIN,
        '/risk-report/daily-profit-over-currency',
    ),
    profitableTradesClosed: path(
        ROOTS_ADMIN,
        '/risk-report/profitable-trades-closed',
    ),
    dailyProfitOverPerc: path(
        ROOTS_ADMIN,
        '/risk-report/daily-profit-over-perc',
    ),
    accountAssessment: path(ROOTS_ADMIN, '/risk-report/account-assessment'),
    riskAccount: path(ROOTS_ADMIN, '/risk-report/risk-account'),
    // risk report
    riskReports: path(ROOTS_ADMIN, '/risk-management/risk-reports'),
    salesReports: path(
        ROOTS_ADMIN,
        '/risk-management/risk-reports/sales-reports',
    ),
    
    taskReports: path(
        ROOTS_ADMIN,
        '/risk-report/task-reports',
    ),
    //notification
    notification: path(ROOTS_ADMIN, '/notification'),
    confidencialDetail: (login) =>
        path(ROOTS_ADMIN, `/confidencial/pamm-detail/${login}`),
    pammAccountList: path(ROOTS_ADMIN, '/confidencial/pamm/'),
    pammAccountSummary: path(ROOTS_ADMIN, '/confidencial/dashboard'),
};

export const PATH_AFTER_LOGIN = paths.dashboard;
export const PATH_AFTER_ADMIN_LOGIN = adminPaths.dashboard;
export const PATH_TO_REDIRECTOR = paths.redirector;

export const navDrawerConfig = ({
    isIB,
    allowDevelop,
    isCompany,
    platformBasicInfo,
    isStock,
    hasStockAccount,
}) => [
    {
        labelKey: 'dashboard',
        value: 'dashboard',
        path: paths.dashboard,
        Icon: HiHome,
        kycValid: false,
    },
    {
        labelKey: 'MT4/5 accounts',
        value: 'accounts',
        path: paths.accounts,
        Icon: MdAccountBalance,
        kycValid: true,
    },
    ...(isStock && hasStockAccount
        ? [
              {
                  labelKey: 'stocks',
                  value: 'stock',
                  path: paths.stock,
                  Icon: CgShutterstock,
                  kycValid: true,
              },
          ]
        : []),
    {
        labelKey: 'debitCard',
        value: 'debitCard',
        path: paths.debitCard,
        Icon: HiCreditCard,
        kycValid: false,
    },

    {
        labelKey: 'funding',
        value: 'funding',
        path: paths.funding,
        Icon: RiFundsBoxFill,
        kycValid: true,
        // subMenu: true,
        // paths: [
        //     {
        //         labelKey: 'deposit',
        //         value: 'deposit',
        //         path: paths.deposits,
        //         kycValid: false,
        //     },
        //     {
        //         labelKey: 'withdrawal',
        //         value: 'withdrawal',
        //         path: paths.withdrawals,
        //         kycValid: false,
        //     },
        //     {
        //         labelKey: 'transfer',
        //         value: 'transfer',
        //         path: paths.transfers,
        //         kycValid: false,
        //     },
        // ],
    },
    ...(isIB
        ? [
              {
                  labelKey: 'ibAgent',
                  value: 'ibAgent',
                  path: paths.ibAgent,
                  Icon: BsPersonBadge,
                  kycValid: true,
              },
          ]
        : [
            //   {
            //       labelKey: 'referral',
            //       value: 'referral',
            //       path: paths.referees,
            //       kycValid: false,
            //       Icon: HiGiftTop,
            //   },
              // {
              //     labelKey: 'referral',
              //     value: 'referral',
              //     path: paths.referralRoot,
              //     Icon: HiGiftTop,
              //     kycValid: false,
              //     subMenu: true,
              //     paths: [
              //         {
              //             labelKey: 'referees',
              //             value: 'referees',
              //             path: paths.referees,
              //             kycValid: false,
              //         },
              //         {
              //             labelKey: 'commission',
              //             value: 'commission',
              //             path: paths.referralCommission,
              //             kycValid: false,
              //         },
              //     ],
              // },
          ]),
    // {
    //     labelKey: 'news',
    //     value: 'news',
    //     path: paths.newsPage,
    //     Icon: IoNewspaperSharp,
    //     kycValid: false,
    // },
    // {
    //     labelKey: 'support',
    //     value: 'support',
    //     path: paths.support,
    //     Icon: BiSupport,
    //     kycValid: false,
    // },
    // ...(kycShow
    //     ? [
    //           {
    //               labelKey: isCompany ? 'kyb' : 'kyc',
    //               value: isCompany ? 'kyb' : 'kyc',
    //               path: isCompany ? paths.kyb : paths.kyc,
    //               Icon: HiCash,
    //               kycValid: false,
    //           },
    //       ]
    //     : []),
    // {
    //     labelKey: 'referral',
    //     value: 'referral',
    //     path: paths.referralRoot,
    //     Icon: HiGiftTop,
    //     kycValid: false,
    //     subMenu: true,
    //     paths: [
    //         {
    //             labelKey: 'referees',
    //             value: 'referees',
    //             path: paths.referees,
    //             kycValid: false,
    //         },
    //         {
    //             labelKey: 'commission',
    //             value: 'commission',
    //             path: paths.referralCommission,
    //             kycValid: false,
    //         },
    //     ],
    // },
    // {
    //     labelKey: 'helpCenter',
    //     value: 'helpCenter',
    //     path: paths.helpCenter,
    //     Icon: FiHelpCircle,
    //     kycValid: false,
    //     subMenu: true,
    //     paths: [
    //         {
    //             labelKey: 'support',
    //             value: 'support',
    //             path: paths.support,
    //             kycValid: false,
    //             new: true,
    //         },
    //         {
    //             labelKey: 'QAndA',
    //             value: 'Q&A',
    //             path: paths.QAndA,
    //             kycValid: false,
    //         },
    //     ],
    // },
    // ...(apiEnabled
    //     ? [
    // {
    //     labelKey: 'APIAccess',
    //     value: 'apis',
    //     path: paths.apiAccess,
    //     Icon: HiOutlineCode,
    //     kycValid: true,
    //     subMenu: true,
    //     paths: [
    //         {
    //             labelKey: 'explorer',
    //             value: 'explorer',
    //             path: devPaths.apis,
    //             external: true,
    //             kycValid: false,
    //         },
    //         {
    //             labelKey: 'apiLog',
    //             value: 'apiLog',
    //             path: paths.apiLog,
    //             kycValid: false,
    //         },
    //         {
    //             labelKey: 'apiKey',
    //             value: 'apiKey',
    //             path: paths.apiKey,
    //             kycValid: false,
    //         },
    //     ],
    // },
    {
        labelKey: 'downloads',
        value: 'downloads',
        path: paths.downloads,
        Icon: CgToolbox,
        kycValid: false,
    },
    // {
    //     labelKey: 'help',
    //     value: 'help',
    //     path: paths.help,
    //     Icon: IoHelpBuoy,
    //     kycValid: false,
    // },
    {
        labelKey: 'aboutUs',
        value: 'aboutUs',
        path: platformBasicInfo?.officialWebsite,
        Icon: FaInfoCircle,
        kycValid: false,
        external: true,
    },
    // {
    //     labelKey: 'components',
    //     value: 'components',
    //     path: paths.components,
    //     Icon: LuComponent,
    // },
    //   ]
    // : []),
];

export const navDrawerAdminConfig = ({getPermission, stockPermissions}) => [
    {
        labelKey: 'dashboard',
        value: 'dashboard',
        path: adminPaths.dashboard,
        Icon: HiHome,
        kycValid: false,
        // hasPermission: getPermission('dashboard'),
    },
    {
        labelKey: 'tasks',
        value: 'tasks',
        path: adminPaths.tasks,
        Icon: HiClipboardList,
        kycValid: false,
        subMenu: true,
        hasPermission: getPermission('tasks'),
        paths: [
            {
                labelKey: 'requests',
                value: 'requests',
                path: adminPaths.requests,
                hasPermission: getPermission('tasks.requests'),
            },
            {
                labelKey: 'taskboard',
                value: 'taskboard',
                path: adminPaths.taskboard,
                hasPermission: getPermission('tasks.taskboard'),
            },
            {
                labelKey: 'myTasks',
                value: 'myTasks',
                path: adminPaths.myTasks,
                hasPermission: getPermission('tasks.myTasks'),
            },
        ],
    },
    {
        labelKey: 'riskApprovals',
        value: 'approvals',
        path: adminPaths.approvals,
        hasPermission: getPermission('riskManagement.approvals'),
        kycValid: false,
        Icon: HiShieldCheck,
    },
    // {
    //     labelKey: 'riskManagement',
    //     value: 'riskManagement',
    //     path: adminPaths.riskManagement,
    //     Icon: HiShieldCheck,
    //     kycValid: false,
    //     subMenu: true,
    //     hasPermission: getPermission('riskManagement'),
    //     paths: [
    //         {
    //             labelKey: 'riskApprovals',
    //             value: 'approvals',
    //             path: adminPaths.approvals,
    //             hasPermission: getPermission('riskManagement.approvals'),
    //         },
    //         // {
    //         //     labelKey: 'riskReports',
    //         //     value: 'riskReports',
    //         //     path: adminPaths.riskReports,
    //         //     hasPermission: getPermission('riskManagement.riskReports'),
    //         // },
    //     ],
    // },
    
    {
        labelKey: 'clients',
        value: 'clients',
        path: adminPaths.clientsHome,
        Icon: HiUserGroup,
        kycValid: false,
        subMenu: true,
        hasPermission: getPermission('clientsHome'),
        paths: [
            {
                labelKey: 'clients',
                value: 'clients',
                path: adminPaths.clients,
                hasPermission: getPermission('clientsHome.clients'),
            },
            {
                labelKey: 'clientsLeads',
                value: 'clientsLeads',
                path: adminPaths.clientsLeads,
                hasPermission: getPermission('clientsHome.clientsLeads'),
            },
            {
                labelKey: 'clientsAccounts',
                value: 'clientsAccounts',
                path: adminPaths.clientsAccounts,
                hasPermission: getPermission('clientsHome.clientsAccounts'),
            },
            {
                labelKey: 'clientsRewireAccounts',
                value: 'clientsRewireAccounts',
                path: adminPaths.clientsRewireAccounts,
                hasPermission: getPermission('clientsHome.clientsRewireAccounts'),
            },
            {
                labelKey: 'clientsPammAccounts',
                value: 'clientsPammAccounts',
                path: adminPaths.clientsPammAccounts,
                hasPermission: getPermission('clientsHome.clientsPammAccounts'),
            },
            {
                labelKey: 'clientsCCSubAccounts',
                value: 'clientsCCSubAccounts',
                path: adminPaths.clientsCCSubAccounts,
                hasPermission: getPermission(
                    'clientsHome.clientsCCSubAccounts',
                ),
            },
            {
                labelKey: 'clientsIbAdmin',
                value: 'clientsIbAdmin',
                path: adminPaths.clientsIbAdmin,
                hasPermission: getPermission('clientsHome.clientsIbAdmin'),
            },
            {
                labelKey: 'managerSales',
                value: 'managerSales',
                path: adminPaths.managerSales,
                hasPermission: getPermission('clientsHome.managerSales'),
            },
            {
                labelKey: 'managerSupports',
                value: 'managerSupports',
                path: adminPaths.managerSupports,
                hasPermission: getPermission('clientsHome.managerSupports'),
            },
            {
                labelKey: 'clientWithdrawalBanks',
                value: 'clientWithdrawalBanks',
                path: adminPaths.clientWithdrawalBanks,
                hasPermission: getPermission(
                    'clientsHome.clientWithdrawalBanks',
                ),
            },
            {
                labelKey: 'clientWithdrawalWallets',
                value: 'clientWithdrawalWallets',
                path: adminPaths.clientWithdrawalWallets,
                hasPermission: getPermission(
                    'clientsHome.clientWithdrawalWallets',
                ),
            },
            {
                labelKey: 'debitCards',
                value: 'debitCards',
                path: adminPaths.debitCards.index,
                hasPermission: getPermission(
                    'clientsHome.demoAccounts',
                ),
            },
            {
                labelKey: 'clientDepositWallets',
                value: 'clientDepositWallets',
                path: adminPaths.clientDepositWallets,
                hasPermission: getPermission(
                    'clientsHome.clientDepositWallets',
                ),
            },
            {
                labelKey: 'clientActivities',
                value: 'clientActivities',
                path: adminPaths.clientActivities,
                hasPermission: getPermission(
                    'clientsHome.clientDepositWallets',
                ),
            },
            {
                labelKey: 'demoAccounts',
                value: 'demoAccounts',
                path: adminPaths.demoAccounts,
                hasPermission: getPermission(
                    'clientsHome.demoAccounts',
                ),
            },
        ],
    },
    {
        labelKey: 'riskReport',
        value: 'riskReport',
        path: adminPaths.riskReport,
        Icon: HiOutlineDocumentReport,
        kycValid: false,
        subMenu: true,
        hasPermission: getPermission('riskManagement'),
        paths: [
            {
                labelKey: 'riskMtTradeMonitor',
                value: 'riskMtTradeMonitor',
                path: adminPaths.riskMtTradeMonitor,
                hasPermission: getPermission(
                    'riskManagement.riskMtTradeMonitor',
                ),
            },
            {
                labelKey: 'closedMonitor',
                value: 'closedMonitor',
                path: adminPaths.closedMtMonitor,
                hasPermission: getPermission(
                    'riskManagement.riskMtTradeMonitor',
                ),
            },
            {
                labelKey: 'profitsAnalysis',
                value: 'profitsAnalysis',
                path: adminPaths.profitsAnalysis,
                hasPermission: getPermission('riskManagement.profitsAnalysis'),
            },
            {
                labelKey: 'commissionAnalysis',
                value: 'commissionAnalysis',
                path: adminPaths.commissionAnalysis,
                hasPermission: getPermission(
                    'riskManagement.commissionAnalysis',
                ),
            },
            {
                labelKey: 'gainersAndLosers',
                value: 'highGainAnalysis',
                path: adminPaths.highGainAnalysis,
                hasPermission: getPermission(
                    'riskManagement.riskMtTradeMonitor',
                ),
            },
            // {
            //     labelKey: 'lowGainAnalysis',
            //     value: 'lowGainAnalysis',
            //     path: adminPaths.lowGainAnalysis,
            //     hasPermission: getPermission(
            //         'riskManagement.riskMtTradeMonitor',
            //     ),
            // },
            {
                labelKey: 'closedInSencond',
                value: 'closedInSencond',
                path: adminPaths.closedInSencond,
                hasPermission: getPermission(
                    'riskManagement.riskMtTradeMonitor',
                ),
            },
            {
                labelKey: 'profitableTradesClosed',
                value: 'profitableTradesClosed',
                path: adminPaths.profitableTradesClosed,
                hasPermission: getPermission(
                    'riskManagement.riskMtTradeMonitor',
                ),
            },
            {
                labelKey: 'profitOverCurrency',
                value: 'profitOverCurrency',
                path: adminPaths.profitOverCurrency,
                hasPermission: getPermission(
                    'riskManagement.riskMtTradeMonitor',
                ),
            },

            // {
            //     labelKey: 'openInSencond',
            //     value: 'openInSencond',
            //     path: adminPaths.openInSencond,
            //     hasPermission: getPermission(
            //         'riskManagement.riskMtTradeMonitor',
            //     ),
            // },
            {
                labelKey: 'dailyProfitOver500USD',
                value: 'dailyProfitOver500USD',
                path: adminPaths.dailyProfitOver500USD,
                hasPermission: getPermission(
                    'riskManagement.riskMtTradeMonitor',
                ),
            },

            {
                labelKey: 'dailyProfitOverPerc',
                value: 'dailyProfitOverPerc',
                path: adminPaths.dailyProfitOverPerc,
                hasPermission: getPermission(
                    'riskManagement.riskMtTradeMonitor',
                ),
            },
            {
                labelKey: 'accountAssessment',
                value: 'accountAssessment',
                path: adminPaths.accountAssessment,
            },
            {
                labelKey: 'riskAccount',
                value: 'riskAccount',
                path: adminPaths.riskAccount,
            },
            // {
            //     labelKey: 'equityReports',
            //     value: 'equityReports',
            //     path: adminPaths.equityReports
            // },
            // {
            //     labelKey: 'taskReports',
            //     value: 'taskReports',
            //     path: adminPaths.taskReports
            // },
            // {
            //     labelKey: 'riskReports',
            //     value: 'riskReports',
            //     path: adminPaths.riskReports,
            //     hasPermission: getPermission('riskManagement.riskReports'),
            // },
        ],
    },
    {
        labelKey: 'manageReports',
        value: 'manageReports',
        path: adminPaths.manageReports,
        Icon: HiOutlineDocumentReport,
        kycValid: false,
        hasPermission: getPermission('manageReports'),
        subMenu: true,
        paths: [
            {
                labelKey: 'equityReport',
                value: 'equityReports',
                path: adminPaths.equityReports,
                hasPermission: getPermission(
                    'manageReports.equityReports',
                ),
            },
            {
                labelKey: 'netReport',
                value: 'netReports',
                path: adminPaths.netReports,
                hasPermission: getPermission(
                    'manageReports.netReports',
                ),
            },
            {
                labelKey: 'closedTrades',
                value: 'closedTrades',
                path: adminPaths.managementReport.pnl,
                hasPermission: getPermission(
                    'manageReports.netReports',
                ),
            },
            {
                labelKey: 'clientReports',
                value: 'clientReports',
                path: adminPaths.clientReports,
                hasPermission: getPermission(
                    'manageReports.clientReports',
                ),
            },
            {
                labelKey: 'manageCommissionReport',
                value: 'manageCommissionReport',
                path: adminPaths.manageCommissionReport,
                hasPermission: getPermission(
                    'manageReports.manageCommissionReports',
                ),
            },
        ]
    },
    ...(stockPermissions?.length
        ? [
              {
                  labelKey: 'stocks',
                  value: 'stocks',
                  path: adminPaths.stocks,
                  Icon: BsCurrencyExchange,
                  kycValid: false,
                  subMenu: true,
                  hasPermission: getPermission('stocksHome'),
                  paths: [
                      {
                          labelKey: 'stockAccounts',
                          value: 'stockAccounts',
                          path: adminPaths.stockAccounts,
                          hasPermission: getPermission(
                              'stocksHome.stockAccounts',
                          ),
                      },
                      {
                          labelKey: 'transactions',
                          value: 'stockTransactions',
                          path: adminPaths.stockTransactions,
                          hasPermission: getPermission(
                              'stocksHome.stockTransactions',
                          ),
                      },
                      {
                          labelKey: 'positions',
                          value: 'stockPositions',
                          path: adminPaths.stockPositions,
                          hasPermission: getPermission(
                              'stocksHome.stockPositions',
                          ),
                      },
                      {
                          labelKey: 'stockProducts',
                          value: 'stockProducts',
                          path: adminPaths.stockProducts,
                          hasPermission: getPermission(
                              'stocksHome.stockProducts',
                          ),
                      },
                      // {
                      //     labelKey: 'requests',
                      //     value: 'stockRequests',
                      //     path: adminPaths.stockRequests,
                      //     hasPermission: getPermission('stocksHome.stockRequests'),
                      // },
                      // {
                      //     labelKey: 'taskboard',
                      //     value: 'stockTaskboard',
                      //     path: adminPaths.stockTaskboard,
                      //     hasPermission: getPermission('stocksHome.stockTaskBoard'),
                      // },
                      // {
                      //     labelKey: 'myTasks',
                      //     value: 'myStockTasks',
                      //     path: adminPaths.myStockTasks,
                      //     hasPermission: getPermission('stocksHome.myStockTasks'),
                      // }
                  ],
              },
          ]
        : []),
    {
        labelKey: 'transactions',
        value: 'transactions',
        path: adminPaths.transactions,
        Icon: BsCurrencyExchange,
        kycValid: false,
        hasPermission: getPermission('transactions'),
    },
    {
        labelKey: 'supports',
        value: 'supports',
        path: adminPaths.supports,
        Icon: HiTicket,
        kycValid: false,
        subMenu: true,
        hasPermission: getPermission('supports'),
        paths: [
            {
                labelKey: 'tickets',
                value: 'tickets',
                path: adminPaths.tickets,
                hasPermission: getPermission('supports.tickets'),
            },
            {
                labelKey: 'faq',
                value: 'faq',
                path: adminPaths.faq,
                hasPermission: getPermission('supports.faq'),
            },
            // {
            //     labelKey: 'incidents',
            //     value: 'incidents',
            //     path: adminPaths.incidents,
            //     hasPermission: getPermission('supports.incidents'),
            // },
            // {
            //     labelKey: 'onlineSupport',
            //     value: 'onlineSupport',
            //     path: adminPaths.onlineSupport,
            //     hasPermission: getPermission('supports.onlineSupport'),
            // },
        ],
    },
    {
        labelKey: 'informationCenter',
        value: 'informationCenter',
        path: adminPaths.informationHome,
        Icon: IoInformationCircle,
        kycValid: false,
        subMenu: true,
        hasPermission: getPermission('informationHome'),
        paths: [
            {
                labelKey: 'informationAnnouncements',
                value: 'informationAnnouncements',
                path: adminPaths.informationAnnouncements,
                hasPermission: getPermission(
                    'informationHome.informationAnnouncements',
                ),
            },
            // {
            //     labelKey: 'informationNews',
            //     value: 'informationNews',
            //     path: adminPaths.informationNews,
            //     hasPermission: getPermission('informationHome.informationNews'),
            // },
            {
                labelKey: 'informationAds',
                value: 'informationAds',
                path: adminPaths.informationAds,
                hasPermission: getPermission('informationHome.informationAds'),
            },
            {
                labelKey: 'informationEmails',
                value: 'informationEmails',
                path: adminPaths.informationHome,
                hasPermission: getPermission(
                    'informationHome.informationEmails',
                ),
            },
            {
                labelKey: 'informationMessages',
                value: 'informationMessages',
                path: adminPaths.informationMessages,
                hasPermission: getPermission(
                    'informationHome.informationMessages',
                ),
            },
            {
                labelKey: 'campaigns',
                value: 'campaigns',
                path: adminPaths.informationAccessSource,
                hasPermission: getPermission(
                    'informationHome.informationAccessSource',
                ),
            },
        ],
    },
    {
        labelKey: 'finance',
        value: 'finance',
        path: adminPaths.finance,
        Icon: HiCurrencyDollar,
        kycValid: false,
        hasPermission: getPermission('finance'),
    },
    
    // {
    //     labelKey: 'netReports',
    //     value: 'netReports',
    //     path: adminPaths.netReports,
    //     Icon: HiCurrencyDollar,
    //     kycValid: false,
    //     hasPermission: getPermission('netReports'),
    // },
    // {
    //     labelKey: 'withAccount',
    //     value: 'withAccount',
    //     path: adminPaths.withAccount,
    //     Icon: HiTicket,
    // },
    {
        labelKey: 'platformSettings',
        value: 'platformSettings',
        path: 'platform-settings',
        subMenu: true,
        Icon: HiCog8Tooth,
        path: adminPaths.platformSettings,
        hasPermission: getPermission('platformSettings'),
        paths: [
            {
                labelKey: 'generalSettings',
                value: 'generalSettings',
                path: adminPaths.generalSettings,
                hasPermission: getPermission(
                    'platformSettings.generalSettings',
                ),
            },
            {
                labelKey: 'mt45Settings',
                value: 'mt45Settings',
                path: adminPaths.mt45Settings,
                hasPermission: getPermission('platformSettings.mt45Settings'),
            },
            {
                labelKey: 'currencies',
                value: 'currencies',
                path: adminPaths.currencies,
                hasPermission: getPermission('platformSettings.currencies'),
            },
            {
                labelKey: 'paymentChannels',
                value: 'paymentChannels',
                path: adminPaths.paymentChannels,
                hasPermission: getPermission(
                    'platformSettings.paymentChannels',
                ),
            },
            {
                labelKey: 'integration',
                value: 'integration',
                path: adminPaths.integration,
                hasPermission: getPermission('platformSettings.integration'),
            },
            {
                labelKey: 'metadata',
                value: 'metadata',
                path: adminPaths.metadata,
                hasPermission: getPermission('platformSettings.metadata'),
            },
            {
                labelKey: 'accessControl',
                value: 'accessControl',
                path: adminPaths.accessControl,
                hasPermission: getPermission('platformSettings.accessControl'),
            },
            {
                labelKey: 'riskLevel',
                value: 'riskLevel',
                path: adminPaths.riskLevel,
                hasPermission: getPermission('platformSettings.riskLevel'),
            },
            {
                labelKey: 'regulationRegion',
                value: 'regulationRegion',
                path: adminPaths.regulationRegion,
                hasPermission: getPermission(
                    'platformSettings.regulationRegion',
                ),
            },
            {
                labelKey: 'systemProducts',
                value: 'systemProducts',
                path: adminPaths.systemProducts,
                hasPermission: getPermission('platformSettings.systemProducts'),
            },
            {
                labelKey: 'debitCard',
                value: 'debitCard',
                path: adminPaths.debitCards.setting.index,
                hasPermission: getPermission('platformSettings.systemProducts'),
            },
        ],
    },
    // {
    //     labelKey: 'applicationManagement',
    //     value: 'applicationManagement',
    //     path: 'application-management',
    //     subMenu: true,
    //     Icon: AiFillAppstore,
    //     path: adminPaths.applicationManagement,
    //     hasPermission: getPermission('applicationManagement'),
    //     paths: [
    //         // {
    //         //     labelKey: 'apiManagement',
    //         //     value: 'apiManagement',
    //         //     path: adminPaths.apiManagement,
    //         //     hasPermission: getPermission(
    //         //         'applicationManagement.apiManagement',
    //         //     ),
    //         // },
    //         {
    //             labelKey: 'systemMonitor',
    //             value: 'systemMonitor',
    //             path: adminPaths.systemMonitorHome,
    //             hasPermission: getPermission(
    //                 'applicationManagement.systemMonitorHome',
    //             ),
    //         },
    //         {
    //             labelKey: 'securityManagement',
    //             value: 'securityManagement',
    //             path: adminPaths.securityManagement,
    //             hasPermission: getPermission(
    //                 'applicationManagement.securityManagement',
    //             ),
    //         },
    //         {
    //             labelKey: 'bulkData',
    //             value: 'bulkData',
    //             path: adminPaths.bulkData,
    //             hasPermission: getPermission('applicationManagement.bulkData'),
    //         },
    //     ],
    // },
];
