import {
    Input,
    Box,
    Stack,
    Textarea,
    Switch,
    FormControl,
    FormLabel,
    Text,
    Spinner,
    InputGroup,
    InputRightElement,
    Checkbox,
    Button,
    HStack,
    useToken,
} from '@chakra-ui/react';
import {useState, useEffect} from 'react';
import {get, isNil} from 'lodash';
import {useTheme} from '@chakra-ui/react';
// components
import CustomDialog from './CustomDialog';
import Selector from 'components/Selector';
import {useTranslation} from 'react-i18next';
import {StyledButton} from 'components/StyledBtn';
import TimeLine from 'components/TimeLine';
import {jsonViewerTheme} from 'constant/jsonViewerTheme';
// utils
import {
    formatDecimalPlaces,
    currencyFormatorWithDecimal,
    RateFormator,
} from 'utils/formator';
// hooks
import useCustomToast from 'hooks/useCustomToast';
import {
    processRequest,
    useRequestDetail,
    approvalRequest,
    processDepositTask,
    processWithdrawalTask,
    processStockPassword,
    approvalWithdrawal,
    approvalFxAccount,
    approvalWallet,
    createBeneficiary_,
    approvalCard
} from 'hooks/datahook/useTasks';
import {useSelectLevels} from 'hooks/datahook/adminClients';
import {useSelectIBs} from 'hooks/datahook/adminClients';
import {useColorModeValueKey} from 'hooks/useColors';
import ImageViewer from 'components/ImageViewer';
import {DisplayBlock} from 'page/app/Receipt';
import {getErrorMessage} from 'utils/error';
import {useSaleList} from 'hooks/datahook/adminClients';
import numeral from 'numeral';
import {SaleCodeInput} from 'components/MaskInput';
import {usePspCallbackLog} from 'hooks/datahook/pspLog';
import ReactJson from 'react-json-view';
import { convertSource } from 'page/app/ConstantGroup';
import CurrencySelector from 'components/CurrencySelector';

export default ({
    open,
    onClose,
    title = 'requestDetail',
    detail = {},
    task = true,
    view = false,
    isPay = false,
    mutate = () => {},
    type,
}) => {
    const {t} = useTranslation('app');
    const [isLoading, setIsLoading] = useState(false);
    const [creatLoading, setCreatLoading] = useState(false);
    const [declineLoading, setDeclineLoading] = useState(false);
    const [declineReason, setDeclineReason] = useState(null);
    const bgColor = useToken('colors', 'gray.900');
    let {
        data,
        isLoading: loading,
        mutate: detailMutate,
    } = useRequestDetail({
        requestId: detail?.id,
        request: open,
    });

    useEffect(() => {
        if (data?.id && !data?.beneficiaryInfo && isPay) {
            createBeneficiary();
        }
    }, [data?.id, isPay]);

    const needRequestPspLog = Boolean(
        get(data, 'type') === 'deposit' &&
            // get(data, ':transaction.:paymentGateway.shortName') === 'Nace' &&
            get(data, ':transaction.ref'),
    );
    const {data: pspLog1, isLoading: isLoadingPspLog1} = usePspCallbackLog({
        transactionRef: needRequestPspLog
            ? get(data, ':transaction.ref')
            : null,
    });
    const {data: pspLog2, isLoading: isLoadingPspLog2} = usePspCallbackLog({
        transactionRef:
            needRequestPspLog && !isLoadingPspLog1 && pspLog1.length == 0
                ? get(data, ':transaction.id')
                : null,
    });
    const pspLog = pspLog1.length != 0 ? pspLog1 : pspLog2;
    const isLoadingPspLog =
        isLoadingPspLog1 ||
        (!isLoadingPspLog1 && pspLog1.length == 0 && isLoadingPspLog2);
    const [showCallback, setShowCallback] = useState(false);
    const [ibCode, setIbCode] = useState(null);
    const [showReason, setShowReason] = useState(false);
    const [level, setLevel] = useState(null);
    const [error, setError] = useState('');
    const [upperIb, setUpperIb] = useState(null);
    const [sale, setSale] = useState(null);
    const [isDecline, setIsDecline] = useState(false);
    const {data: levels} = useSelectLevels();
    const {data: ibList} = useSelectIBs();
    const {data: sales} = useSaleList();
    const [imgOpen, setImgOpen] = useState(false);
    const [realAmount, setRealAmount] = useState(null);
    const [realRate, setRealRate] = useState(null);
    const [realCurrency, setRealCurrency] = useState(null)
    const [paymentReference, setPaymentReference] = useState(null);
    const [isDeposit, setIsDeposit] = useState(false);
    const [stockPassword, setStockPassword] = useState(null)
    // let data = detail;
    const toast = useCustomToast();

    useEffect(() => {
        if (get(data, 'type') === 'deposit') {
            setRealAmount(get(data, ':transaction.toAmount'));
        }
    }, [loading, get(data, ':transaction.toAmount')]);

    const initialAmount =
        Number(
            get(data, 'params.from.amount') || get(data, 'params.fromAmount'),
        ) - Number(get(data, 'params.fee'));
    useEffect(() => {
        if (open && data?.id && realRate === null) {
            setRealRate(get(data, 'rate'));
            const initialAmount =
                Number(
                    get(data, 'params.from.amount') ||
                        get(data, 'params.fromAmount'),
                ) - Number(get(data, 'params.fee'));
            setRealAmount(
                formatDecimalPlaces(
                    Number(initialAmount) * Number(get(data, 'rate')),
                    2,
                ),
            );
            setRealCurrency(get(data, 'params.to.currency')||get(data, 'params.to.cryptoCurrency'))
        }
    }, [data?.id, open]);
    const detailRow = [
        {
            label: t('ticket'),
            value: get(data, 'ticket'),
        },
        {
            label: t('client'),
            value: get(data, 'clientDetail.name'),
        },
        {
            label: t('clientReference'),
            value:
                get(data, 'clientDetail.reference') || get(data, ':user.ref'),
        },
        ...(get(data, 'type') === 'accountPasswordChange'? [
            {
                label: t('account'),
                value: get(data, 'params.login', get(data, 'params.clientCode')),
            }
        ]: []),
        ...(get(data, 'type') === 'deposit'? [
            {
                label: t('refId'),
                value: get(data, ':transaction.ref'),
            }
        ]: []),
        {
            label: t('transactionType'),
            value: (get(data, 'type') === 'Account' && get(data, 'subType') === 'DebitCard')? t('debitCardAccount'):t(get(data, 'type')),
            action:
                needRequestPspLog && !isLoadingPspLog && pspLog ? (
                    <Button
                        size="xs"
                        onClick={() => setShowCallback((c) => !c)}
                        variant={'primary'}
                    >
                        {t(showCallback ? 'hideCallback' : 'showCallback')}
                    </Button>
                ) : null,
            component: (
                <Box>
                    <Text>{t(get(data, 'type'))}</Text>
                    {showCallback && (
                        <Box bg="gray.900" p={3} wordBreak={'break-all'}>
                            <ReactJson
                                src={pspLog}
                                theme={{
                                    ...jsonViewerTheme,
                                    base00: bgColor,
                                }}
                            />
                        </Box>
                    )}
                </Box>
            ),
        },
        ...(get(data, 'type') === 'account'
            ? [
                  {
                      label: t('currency'),
                      value: get(
                          data,
                          ':fxAccount.currency',
                          get(data, 'params.currency'),
                      ),
                  },
                  {
                      label: t('leverage'),
                      value: `1: ${get(data, 'params.leverage')}`,
                  },
                  {
                      label: t('group'),
                      value: get(
                          data,
                          ':fxAccount.group',
                          get(data, 'params.groupType'),
                      ),
                  },
              ]
            : []),
        ...(get(data, 'type') === 'deposit' ||
        get(data, 'type') === 'withdrawal'
            ? [
                  {
                      label: t('method'),
                      value:
                          get(data, 'subMethod') === 'VA'
                              ? 'CC Subaccount'
                              : t(get(data, 'params.method')) ||
                                t(get(data, 'paymentGateway')) ||
                                t(get(data, 'method')),
                  },
              ]
            : []),
        {
            label: t('status'),
            value:
                type === 'request'
                    ? t(get(data, 'status'))
                    : type === 'task'
                    ? t(get(data, ':task.status'))
                    : t(get(data, ':approval.status')),
        },
        ...(get(data, 'type') === 'accountLeverageChange'
            ? [
                  {
                      label: t('leverage'),
                      value: `1: ${get(data, 'params.leverage')}`,
                  },
                  {
                      label: t('group'),
                      value: get(data, 'params.group'),
                  },
                  {
                      label: t('balance'),
                      value: currencyFormatorWithDecimal(
                          get(data, 'params.balance'),
                          get(data, 'params.currency', ''),
                          2,
                          true,
                      ),
                  },
              ]
            : []),
        ...(get(data, 'type') === 'deposit'
            ? [
                  {
                      label: t('fromAmount'),
                      value: currencyFormatorWithDecimal(
                          get(data, 'params.from.amount') ||
                              get(data, 'params.fromAmount'),
                          get(data, 'params.from.crypto') ||
                              get(data, 'params.from.currency'),
                          get(data, 'params.from.crypto') ? 8 : 2,
                          true,
                      ),
                  },
                  {
                      label: t('toAccount'),
                      value: `${convertSource(get(data, 'params.to.source'))}-${get(
                          data,
                          'params.to.login',
                      )}`,
                  },
                  {
                      label: t('toAmount'),
                      value: currencyFormatorWithDecimal(
                          get(data, ':transaction.toAmount'),
                          get(data, ':transaction.toCurrency'),
                          2,
                          true,
                      ),
                  },
              ]
            : []),
        ...(get(data, 'type') === 'withdrawal' ||
        get(data, 'type') === 'transfer'
            ? [
                  {
                      label: t('fromAccount'),
                      value:
                          get(data, 'type') === 'commission'
                              ? get(data, 'ticket')
                              : get(data, 'type') === 'adjustment'
                              ? t('agent')
                              : get(data, 'type') === 'withdrawal' ||
                                get(data, 'type') === 'transfer'
                              ? `${convertSource(get(data, 'params.from.source'))}-${get(
                                    data,
                                    'params.from.login',
                                )}`
                              : get(data, 'type') === 'Deposit'
                              ? get(data, 'params.fromAccountName')
                              : get(data, 'fromAccount'),
                  },
                  {
                      label: t('fromAmount'),
                      value: currencyFormatorWithDecimal(
                          get(data, 'params.from.amount'),
                          get(data, 'params.from.currency') ||
                              get(data, 'params.toCurrency'),
                          2,
                          true,
                      ),
                  },
              ]
            : []),
        ...(get(data, 'type') === 'withdrawal' ||
        get(data, 'type') === 'transfer'
            ? [
                  {
                      label: t('toAccount'),
                      value:
                          get(data, 'type') === 'adjustment'
                              ? t('agent')
                              : get(data, 'type') === 'transfer'
                              ? `${convertSource(get(data, 'params.to.source'))}-${get(
                                    data,
                                    'params.to.login',
                                )}`
                              : get(data, 'type') === 'withdrawal'
                              ? get(data, 'params.to.type') === 'currency'
                                  ? `${get(data, 'params.to.routeType')}-${get(
                                        data,
                                        'params.to.accountNumber',
                                    )}`
                                  : get(data, ':withdrawalAccount.displayName')
                              : get(data, 'toAccount'),
                  },
                  {
                      label: t('toAmount'),
                      value: currencyFormatorWithDecimal(
                          get(data, 'params.to.amount'),

                          get(data, 'params.to.currency') ||
                              get(data, 'params.to.cryptoCurrency') ||
                              get(data, 'params.currency'),
                          get(data, 'params.to.cryptoCurrency') &&
                              get(data, 'type') === 'withdrawal'
                              ? 8
                              : 2,
                          true,
                      ),
                  },
              ]
            : []),
        ...((get(data, 'type') === 'commission' ||
            get(data, 'type') === 'deposit' ||
            get(data, 'type') === 'withdrawal') &&
        get(data, 'rate')
            ? [
                  {
                      label: t('rate'),
                      value: `${RateFormator(get(data, 'rate')) || 1}:1`,
                  },
              ]
            : []),
        ...(get(data, 'type') === 'commission' ||
        get(data, 'type') === 'deposit' ||
        get(data, 'type') === 'withdrawal'
            ? [
                  {
                      label: t('fee'),
                      value: currencyFormatorWithDecimal(
                          get(data, 'params.fee') || 0,
                          get(data, 'params.from.currency'),
                      ),
                  },
              ]
            : []),
        ...(get(data, 'type') === 'withdrawalAccount'
            ? [
                  {
                      label: t('currency'),
                      value: get(data, 'params.currency'),
                  },
                  {
                      label: t('country'),
                      value: get(data, 'params.country'),
                  },
              ]
            : []),
        ...(get(data, 'type') === 'withdrawalWallet'
            ? [
                  {
                      label: t('currency'),
                      value: get(data, 'params.currency'),
                  },
                  {
                      label: t('accountNumber'),
                      value: get(data, 'params.accountNumber'),
                  },
                  {
                      label: t('chainType'),
                      value: get(data, 'params.chainType'),
                  },
              ]
            : []),
        ...(get(data, 'type') === 'exchangeWithdrawal'
            ? [
                  {
                      label: t('currency'),
                      value: get(data, 'params.currency'),
                  },
                  {
                      label: t('cryptoExchange'),
                      value: get(data, 'params.exchange'),
                  },
                  {
                      label: t('kycImg'),
                      value: get(data, 'params.kycImg'),
                      isImg: true,
                  },
                  {
                      label: t('ercWalletImg'),
                      value: get(data, 'params.erc20walletImg'),
                      isImg: true,
                  },
                  {
                      label: t('ercWallet'),
                      value: get(data, 'params.ercWallet'),
                  },
                  {
                      label: t('tronWalletImg'),
                      value: get(data, 'params.tronWalletImg'),
                      isImg: true,
                  },
                  {
                      label: t('tronWallets'),
                      value: get(data, 'params.tronWallet'),
                  },
              ]
            : []),
        ...(get(data, 'type') === 'Account'&&get(data, 'subType') === 'DebitCard'
            ? [
                {
                    label: t('cardType'),
                    value: get(data, 'params.cardType'),
                },
                {
                    label: t('fee'),
                    value: currencyFormatorWithDecimal(
                        get(data, 'params.fee') || 0,
                        'USD',
                    ),
                },
            ]
            : []
        ),
        {
            label: t('desc'),
            value: get(data, 'description'),
        },
        ...(!isNil(get(data, 'isCryptoAccountSignValid'))
            ? [
                  {
                      label: t('cryptoPassSignature'),
                      value: get(data, 'isCryptoAccountSignValid')
                          ? t('valid')
                          : t('invalid'),
                  },
              ]
            : []),
        ...(get(data, 'params.comment')
            ? [
                  {
                      label: t('comment'),
                      value: get(data, 'params.comment'),
                  },
              ]
            : []),
        ...(get(data, 'params.paymentReference')
            ? [
                  {
                      label: t('paymentReference'),
                      value: get(data, 'params.paymentReference'),
                  },
              ]
            : []),
        // ...(get(data, 'status') === 'declined' ||
        // (get(data, 'type') === 'kyc' &&
        //     get(data, ':approval.status') === 'declined')
        //     ? [
        //           {
        //               label: t('declineReason'),
        //               value: get(data, 'declineReason'),
        //           },
        //       ]
        //     : []),
        ...(get(data, 'declineReason')
            ? [
                  {
                      label: t('declineReason'),
                      value: get(data, 'declineReason'),
                  },
              ]
            : []),
        ...(get(data, 'failedReason')
            ? [
                  {
                      label: t('failedReason'),
                      value: get(data, 'failedReason'),
                  },
              ]
            : []),
        ...(get(data, 'params.attachment')
            ? [
                  {
                      label: t('attachment'),
                      value: get(data, 'params.attachment'),
                      isImg: true,
                  },
              ]
            : []),
        ...(isPay && get(data, 'beneficiaryInfo')
            ? [
                  {
                      label: t('beneficiary'),
                      value:
                          get(data, 'beneficiaryInfo.companyName') ||
                          get(data, 'beneficiaryInfo.legalName'),
                  },
                  {
                      label: t('accountNumber'),
                      value: get(data, 'beneficiaryInfo.accountNumber'),
                  },
                  {
                      label: t('bankAccountHolderName'),
                      value: get(data, 'beneficiaryInfo.bankAccountHolderName'),
                  },
                  ...(get(data, 'params.paymentResult')
                      ? [
                            {
                                label: t('paymentSendingStatus'),
                                value: t(
                                    get(data, 'params.paymentResult.status'),
                                ),
                            },
                        ]
                      : []),
              ]
            : []),
    ];
    const onProcess = async () => {
        console.log(data);
        try {
            setIsLoading(true);
            if (task) {
                if (get(data, 'type') === 'deposit') {
                    await processDepositTask(
                        get(data, 'taskId'),
                        'processed',
                        declineReason,
                        isDeposit,
                        Number(realAmount),
                    );
                } else if (get(data, 'type') === 'withdrawal') {
                    const processMethod = isPay ? 'CC-EFT' : null;
                    await processWithdrawalTask(
                        get(data, 'taskId'),
                        'processed',
                        declineReason,
                        Number(realAmount),
                        Number(realRate),
                        paymentReference,
                        processMethod,
                        realCurrency
                    );
                } else if (get(data, 'type') === 'accountPasswordChange'&& get(data, 'source') === 'Stock'){
                    setError('')
                    if(!stockPassword){
                        setError(t('requiredField', {fieldName: t('password')}));
                        setIsLoading(false);
                        return
                    }
                    await processStockPassword(data?.taskId, { password: stockPassword, taskId: get(data, 'taskId'), reason: declineReason, status: 'processed' })
                } else {
                    await processRequest(data?.taskId, 'processed');
                }
            } else {
                if (get(data, 'type') === 'ibApplication') {
                    if (ibCode === null) {
                        setError(t('requiredField', {fieldName: t('ibCode')}));
                        setIsLoading(false);
                        return;
                    }
                    if (sale === null) {
                        setError(t('requiredField', {fieldName: t('sale')}));
                        setIsLoading(false);
                        return;
                    }
                    await approvalRequest(
                        data?.approvalId,
                        'approved',
                        null,
                        get(data, 'type'),
                        numeral(Number(level) / 100).format('0.00'),
                        upperIb,
                        sale,
                        ibCode,
                        levels[0].id,
                    );
                } else if (get(data, 'type') === 'withdrawal') {
                    await approvalWithdrawal(
                        get(data, 'approvalId'),
                        'approved',
                    );
                } else if (get(data, 'type') === 'account') {
                    await approvalFxAccount(
                        get(data, 'approvalId'),
                        'approved',
                    );
                } else if (get(data, 'type') === 'exchangeWithdrawal') {
                    await approvalWallet(get(data, 'approvalId'), 'approved');
                } else if (get(data, 'type') === 'Account' && get(data, 'subType') === 'DebitCard') {
                    console.log(data)
                    await approvalCard(get(data, 'id'), 'approved')
                } else {
                    await approvalRequest(
                        data?.approvalId,
                        'approved',
                        null,
                        get(data, 'type'),
                    );
                }
            }
            await mutate();
            setRealAmount(null);
            setRealRate(null);
            setPaymentReference(null);
            setIsDeposit(false);
            toast.show({
                title: task ? t('processSuccess') : t('approvalSuccess'),
                status: 'success',
            });
            setIsLoading(false);
            onClose();
        } catch (error) {
            onClose();
            setIsLoading(false);
            toast.show({
                title: `${task ? t('processFailed') : t('approvalFailed')}`,
                status: 'error',
                desc: getErrorMessage(error),
            });
        }
    };
    const onDecline = async () => {
        try {
            if (!showReason) {
                setShowReason(true);
            } else {
                if (declineReason) {
                    setDeclineLoading(true);
                    if (task) {
                        if (get(data, 'type') === 'deposit') {
                            await processDepositTask(
                                get(data, 'taskId'),
                                'declined',
                                declineReason,
                            );
                        } else if (get(data, 'type') === 'withdrawal') {
                            await processWithdrawalTask(
                                get(data, 'taskId'),
                                'declined',
                                declineReason,
                            );
                        } else if (get(data, 'type') === 'accountPasswordChange'&& get(data, 'source') === 'Stock'){
                            setError('')
                            await processStockPassword(data?.taskId, { taskId: get(data, 'taskId'), reason: declineReason, status: 'declined' })
                        } else {
                            await processRequest(
                                data?.taskId,
                                'declined',
                                declineReason,
                            );
                        }
                    } else {
                        if (get(data, 'type') === 'withdrawal') {
                            await approvalWithdrawal(
                                get(data, 'approvalId'),
                                'declined',
                                declineReason,
                            );
                        } else if (get(data, 'type') === 'account') {
                            await approvalFxAccount(
                                get(data, 'approvalId'),
                                'declined',
                                declineReason,
                            );
                        } else if (get(data, 'type') === 'exchangeWithdrawal') {
                            await approvalWallet(
                                get(data, 'approvalId'),
                                'declined',
                                declineReason,
                            );
                        } else if (get(data, 'type') === 'Account' && get(data, 'subType') === 'DebitCard') {
                            await approvalCard(get(data, 'id'), 'declined', declineReason)
                        } else {
                            await approvalRequest(
                                data?.approvalId,
                                'declined',
                                declineReason,
                                get(data, 'type'),
                            );
                        }
                    }
                    await mutate();
                    toast.show({
                        title: t('declineSuccess'),
                        status: 'success',
                    });
                    setError('');
                    setShowReason(false);
                    setDeclineReason(null);
                    setDeclineLoading(false);
                    onClose();
                } else {
                    setError(
                        t('requiredField', {fieldName: t('declineReason')}),
                    );
                }
            }
        } catch (error) {
            setDeclineLoading(false);
            toast.show({
                title: t('declineFailed'),
                status: 'error',
                desc: getErrorMessage(error),
            });
        }
    };

    const createBeneficiary = async () => {
        try {
            setCreatLoading(true);
            await createBeneficiary_({
                withdrawalAccountId: detail?.withdrawalAccountId,
            });
            await detailMutate();
            toast.show({
                title: t('createSuccess'),
                status: 'success',
            });
        } catch (error) {
            toast.show({
                title: t('createFiled'),
                status: 'error',
                description: getErrorMessage(error),
            });
        } finally {
            setCreatLoading(false);
        }
    };

    return (
        <CustomDialog
            open={open}
            onClose={() => {
                onClose();
                setShowReason(false);
                setIsDecline(false);
                setDeclineReason(null);
                setRealAmount(null);
                setRealRate(null);
                setPaymentReference(null);
                setIsDeposit(false);
            }}
            actions={
                get(data, 'status') === 'pending' &&
                (get(data, 'type') === 'accountLeverageChange'
                    ? true
                    : !view) ? (
                    <Box>
                        {isDecline ? (
                            <>
                                <StyledButton
                                    variant="ghost"
                                    mr={4}
                                    fontSize={12}
                                    onClick={() => {
                                        setIsDecline(false);
                                        onClose();
                                        setShowReason(false);
                                        setDeclineReason(null);
                                        setRealAmount(null);
                                        setRealRate(null);
                                        setPaymentReference(null);
                                        setIsDeposit(false);
                                    }}
                                >
                                    {t('cancel')}
                                </StyledButton>
                                <StyledButton
                                    mr={4}
                                    isLoading={declineLoading}
                                    fontSize={12}
                                    onClick={() => onDecline()}
                                >
                                    {(get(data, 'type') === 'Account'&&get(data, 'subType') === 'DebitCard')?t('return'):t('decline')}
                                </StyledButton>
                            </>
                        ) : (
                            <>
                                {isPay ? (
                                    <StyledButton
                                        isDisabled={
                                            !get(data, 'beneficiaryInfo')
                                        }
                                        mr={4}
                                        isLoading={isLoading}
                                        fontSize={12}
                                        onClick={() => onProcess()}
                                    >
                                        {t('pay')}
                                    </StyledButton>
                                ) : (
                                    <>
                                        <StyledButton
                                            variant="ghost"
                                            mr={4}
                                            fontSize={12}
                                            isLoading={declineLoading}
                                            onClick={() => {
                                                onDecline();
                                                setError('');
                                                setIsDecline(true);
                                            }}
                                        >
                                            {t('decline')}
                                        </StyledButton>
                                        <StyledButton
                                            mr={4}
                                            isLoading={isLoading}
                                            fontSize={12}
                                            onClick={() => onProcess()}
                                        >
                                            {task ? t('process') : t('approve')}
                                        </StyledButton>
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                ) : (
                    <></>
                )
            }
            title={t(title)}
            zIndex={999}
        >
            {loading ? (
                <Box display={'flex'} justifyContent="center">
                    <Spinner />
                </Box>
            ) : (
                <Stack>
                    {detailRow.map((item) => {
                        if (get(item, 'isImg')) {
                            return (
                                <Stack>
                                    <Text fontSize={'xs'}>{item.label}</Text>
                                    <DisplayBlock file={get(item, 'value')} />
                                </Stack>
                            );
                        }
                        return (
                            <Stack spacing={0}>
                                <HStack alignItems={'center'} spacing={2}>
                                    <Text fontSize={'xs'}>{item.label}</Text>
                                    {item.action ? item.action : <></>}
                                </HStack>
                                {item.action ? (
                                    item.component
                                ) : (
                                    <Text>{item.value}</Text>
                                )}
                            </Stack>
                        );
                    })}
                    {
                        get(data, 'type') === 'accountPasswordChange' && view === false && get(data, 'source') === 'Stock' ? (
                            <Stack>
                                <FormControl>
                                    <FormLabel>{t('newPassword')}</FormLabel>
                                    <Input
                                        size="sm"
                                        value={stockPassword}
                                        onChange={(e) => {
                                            setStockPassword(e.target.value);
                                        }}
                                    />
                                </FormControl>
                                <Text
                                fontSize={'xs'}
                                color={useColorModeValueKey('error')}
                                >
                                    {error}
                                </Text>
                            </Stack>
                        ): (
                            ''
                        )
                    }
                    {get(data, 'type') === 'deposit' && view === false ? (
                        <>
                            <Checkbox
                                isChecked={isDeposit}
                                value="isDeposit"
                                onChange={(e) => setIsDeposit(e.target.checked)}
                            >
                                {t('adjustAmount')}
                            </Checkbox>
                            {isDeposit ? (
                                <FormControl>
                                    <FormLabel>{t('realAmount')}</FormLabel>
                                    <Input
                                        type={'number'}
                                        size="sm"
                                        value={realAmount}
                                        onChange={(e) => {
                                            setRealAmount(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        ''
                    )}
                    {get(data, 'type') === 'ibApplication' && view === false ? (
                        <>
                            <FormControl>
                                <FormLabel>{t('ibCode')}*</FormLabel>
                                <SaleCodeInput
                                    size="sm"
                                    value={ibCode}
                                    onChange={(e) => setIbCode(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('sale')}*</FormLabel>
                                <Selector
                                    options={sales.map((item) => {
                                        return {
                                            label: t(get(item, 'name')),
                                            value: get(item, 'id'),
                                        };
                                    })}
                                    value={sale}
                                    onChangeValue={(value) => {
                                        setError('');
                                        setSale(value);
                                    }}
                                />
                                <Text
                                    fontSize={'xs'}
                                    color={useColorModeValueKey('error')}
                                >
                                    {error}
                                </Text>
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('upLevelIb')}</FormLabel>
                                <Selector
                                    options={ibList}
                                    value={upperIb}
                                    onChangeValue={(value) => setUpperIb(value)}
                                />
                                <Text
                                    fontSize={'xs'}
                                    color={useColorModeValueKey('error')}
                                >
                                    {error}
                                </Text>
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('rate')}*</FormLabel>
                                <InputGroup size="sm">
                                    <Input
                                        size="sm"
                                        value={level}
                                        onChange={(e) =>
                                            setLevel(e.target.value)
                                        }
                                    />
                                    <InputRightElement size="sm">
                                        %
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                        </>
                    ) : (
                        ''
                    )}
                    {get(data, 'type') === 'withdrawal' &&
                        get(data, 'taskId') &&
                        !view &&
                        !showReason && (
                            <Stack>
                                {isPay && !get(data, 'beneficiaryInfo') && (
                                    <Stack>
                                        <Text fontSize={'xs'}>
                                            {t('beneficiaryCreationStatus')}
                                        </Text>
                                        <HStack>
                                            <Text>{t('notCreated')}</Text>
                                            <StyledButton
                                                mr={4}
                                                isLoading={creatLoading}
                                                fontSize={12}
                                                onClick={() =>
                                                    createBeneficiary()
                                                }
                                            >
                                                {t('createBeneficiary')}
                                            </StyledButton>
                                        </HStack>
                                    </Stack>
                                )}
                                <FormControl>
                                    <FormLabel>{t('realRate')}</FormLabel>
                                    <Input
                                        type={'number'}
                                        size="sm"
                                        value={realRate}
                                        onChange={(e) => {
                                            setRealRate(e.target.value);
                                            setRealAmount(
                                                e.target.value
                                                    ? formatDecimalPlaces(
                                                          Number(
                                                              initialAmount,
                                                          ) *
                                                              Number(
                                                                  e.target
                                                                      .value,
                                                              ),
                                                          2,
                                                      )
                                                    : null,
                                            );
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>{t('realCurrency')}</FormLabel>
                                    {
                                        get(data, 'params.to.type') === 'currency' ? 
                                            <CurrencySelector
                                                size="sm"
                                                value={realCurrency}
                                                onValueChange={(newValue) => {
                                                    setRealCurrency(newValue);
                                                }}
                                            />: 
                                            <Text>{get(data, 'params.to.cryptoCurrency')||get(data, 'params.to.currency')}</Text>
                                    }
                                </FormControl>
                                <FormControl>
                                    <FormLabel>{t('realAmount')}</FormLabel>
                                    <Input
                                        type={'number'}
                                        size="sm"
                                        value={realAmount}
                                        onChange={(e) => {
                                            setRealAmount(e.target.value);
                                            setRealRate(
                                                e.target.value
                                                    ? formatDecimalPlaces(
                                                          Number(
                                                              e.target.value,
                                                          ) /
                                                              Number(
                                                                  initialAmount,
                                                              ),
                                                          5,
                                                      )
                                                    : null,
                                            );
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        {t('paymentReference')}
                                    </FormLabel>
                                    <Textarea
                                        h={32}
                                        size={'sm'}
                                        onChange={(e) =>
                                            setPaymentReference(e.target.value)
                                        }
                                        value={paymentReference}
                                        placeholder={t('enter')}
                                    />
                                </FormControl>
                            </Stack>
                        )}
                    <TimeLine data={data} type={type} />
                    {showReason ||
                    (get(data, 'type') === 'deposit' &&
                        type === 'task' &&
                        !view) ||
                    (get(data, 'type') === 'withdrawal' &&
                        type === 'task' &&
                        !view) ? (
                        <Stack>
                            <FormControl>
                                <FormLabel>{t('reason')}</FormLabel>
                                <Textarea
                                    value={declineReason}
                                    onChange={(e) =>
                                        setDeclineReason(e.target.value)
                                    }
                                />
                            </FormControl>
                            <Text
                                fontSize={'xs'}
                                color={useColorModeValueKey('error')}
                            >
                                {error}
                            </Text>
                        </Stack>
                    ) : (
                        ''
                    )}
                </Stack>
            )}
        </CustomDialog>
    );
};
