import {
    Avatar,
    Box,
    Button,
    Center,
    DarkMode,
    Divider,
    Flex,
    HStack,
    Heading,
    Icon,
    IconButton,
    LightMode,
    Stack,
    Text,
    Tooltip,
    useBreakpointValue,
} from '@chakra-ui/react';
import {useAutoLogout} from 'hooks/useAutoLogout';
import {useColorModeValueKey} from 'hooks/useColors';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useLocation, useMatches, useNavigate} from 'react-router-dom';
import {useUIStore} from 'stores/uiStore';
import {HiOutlineMenu} from 'react-icons/hi';
import {camelCase, get, last, split, trim} from 'lodash';
import LanguageBtn from 'components/LanguageBtn';
import ColorModeBtn from 'components/ColorModeBtn';
import NotificationBtn from 'components/NotificationBtn';
import ProfileBtn from 'components/ProfileBtn';
import CustomBreadcrumb from 'components/CustomBreadcrumb';
import {AppLogoArea, MobileNavDrawer, NavDrawer} from './NavBar';
import {useAuth} from 'hooks/useAuth';
import {CgArrowsExchangeAlt} from 'react-icons/cg';
import {designSystemColors} from 'theme';
import {motion} from 'framer-motion';
import {useAdminProfile, useProfile} from 'hooks/datahook/auth';
import ShieldExclamationMini from 'components/Icon/ShieldExclamationMini';
import ShieldCheckMini from 'components/Icon/ShieldCheckMini';
import QRCode from 'components/Icon/QRCode';
import {paths} from 'routes/path';
import BrochureDialog from 'components/Dialog/BrochureDialog';
import PlatformCurrencyBtn from 'components/PlatformCurrencyBtn';
import {
    HiArrowRight,
    HiBanknotes,
    HiCurrencyDollar,
    HiOutlineBanknotes,
    HiPencil,
    HiPlus,
    HiQuestionMarkCircle,
    HiUserPlus,
    HiWallet,
} from 'react-icons/hi2';
import {IoDiamondSharp} from 'react-icons/io5';
import {useDepositStore} from 'stores/depositStore';
import ApplyForexAccount from 'page/app/Forex/ApplyForexAccount';
import ApplyIB from 'page/app/ApplyIB';
import {formatDateTimezone} from '../utils/formator';
import {IoHelpOutline} from 'react-icons/io5';
import {IoMdTime} from 'react-icons/io';
import {addSeconds} from 'date-fns';
import {format as tzFormat, utcToZonedTime} from 'date-fns-tz';
import {useForexAccountList} from '../hooks/datahook/forex';
import InviteDialog from 'components/Dialog/InviteDialog';
import {Helmet} from 'react-helmet-async';
import ClientHDWalletDialog from 'components/Dialog/ClientHDWalletDialog';
import {
    useClientHDWallet,
    useClientPlatformHDWalletStatus,
} from 'hooks/datahook/hdwallet';
import CryptoIcon from 'components/CryptoIcon';
import HDWalletAlertDialog from 'components/Dialog/HDWalletAlertDialog';
import {userHdAlertStore} from 'stores/hdAlertStore';

const MotionStack = motion(Stack);
const MotionBox = motion(Box);

const NavBarItem = () => {
    const {t} = useTranslation('app');
    const {
        data: profile,
        kycVerified,
        kycPending,
        kycRequired,
        isCompany,
        isIB,
    } = useProfile();
    const successColor = useColorModeValueKey('success');
    const textColor = useColorModeValueKey('text');
    const warningColor = useColorModeValueKey('warning');
    const grayColor = useColorModeValueKey('gray');
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openHDWallet, setOpenHDWallet] = useState(false);
    const [cryptoOnly, setCryptoOnly] = useState(null);
    const [sideBarOpen] = useUIStore((state) => [state.sideBarOpen]);
    const [
        showHDAlertDialog,
        setShowHDAlertDialog,
        hasShowHDAlertDialog,
        setHasShowHDAlertDialog,
    ] = userHdAlertStore((state) => [
        state.showHDAlertDialog,
        state.setShowHDAlertDialog,
        state.hasShowHDAlertDialog,
        state.setHasShowHDAlertDialog,
    ]);

    const StatusIcon = kycVerified ? ShieldCheckMini : ShieldExclamationMini;

    const {data: status} = useClientPlatformHDWalletStatus();
    const {data: clientWallet} = useClientHDWallet({request: status?.enable});

    useEffect(() => {
        if (clientWallet?.result && !hasShowHDAlertDialog) {
            setShowHDAlertDialog(true);
            setHasShowHDAlertDialog(true);
        }
    }, [clientWallet?.result, hasShowHDAlertDialog]);
    return (
        <>
            <HStack px={3} alignItems={'flex-start'}>
                <Avatar
                    height={sideBarOpen ? '40px' : '40px'}
                    width={sideBarOpen ? '40px' : '40px'}
                    // borderRadius={'4'}
                    src={get(profile, 'avatar')}
                    name={get(profile, 'name')}
                    onClick={() => navigate(paths.profile)}
                    cursor={'pointer'}
                />
                {/* {sideBarOpen && ( */}
                <MotionBox
                    spacing={0}
                    lineHeight={1.3}
                    minWidth={'170px'}
                    animate={
                        sideBarOpen
                            ? {
                                  opacity: 1,
                                  display: 'block',
                              }
                            : {
                                  opacity: 0,
                                  height: '40px',
                                  transitionEnd: {
                                      display: 'none',
                                  },
                              }
                    }
                    transition={'linear'}
                    initial={false}
                    tran
                >
                    <Text mt={0} color={textColor} noOfLines={2}>
                        {get(profile, 'name')}
                    </Text>
                    <Text fontSize={'xs'} color={grayColor}>
                        Ref: {get(profile, 'reference')}
                    </Text>
                    <HStack spacing={1} alignItems={'center'} mt={0} ml={-1}>
                        <Tooltip
                            label={t('kycStatusWith', {
                                status: t(get(profile, 'kycStatus')),
                            })}
                            hasArrow
                        >
                            <IconButton
                                p={0}
                                size="xs"
                                variant={'unstyled'}
                                onClick={() =>
                                    kycRequired || kycPending
                                        ? navigate(
                                              isCompany ? paths.kyb : paths.kyc,
                                          )
                                        : navigate(paths.profileKycReview)
                                }
                                icon={
                                    <StatusIcon
                                        boxSize={4}
                                        color={
                                            kycVerified
                                                ? successColor
                                                : warningColor
                                        }
                                    />
                                }
                            />
                        </Tooltip>
                        {isIB && 
                        <Tooltip label={t('brochure')} hasArrow>
                            <IconButton
                                p={0}
                                size="xs"
                                variant={'unstyled'}
                                onClick={() => setOpen(true)}
                                icon={<QRCode color={textColor} boxSize={4} />}
                            />
                        </Tooltip>
                        }
                        {kycVerified &&
                            status?.enable &&
                            clientWallet?.result && (
                                <>
                                    {status?.ETH && (
                                        <Tooltip
                                            label={
                                                'Under maintenance. Please do not deposit in your wallet. Please use USDT@NACE Pay instead.'
                                            }
                                            hasArrow
                                        >
                                            <IconButton
                                                p={0}
                                                pt={1}
                                                size="xs"
                                                variant={'unstyled'}
                                                onClick={() => {
                                                    setCryptoOnly('ETH');
                                                    setOpenHDWallet(true);
                                                }}
                                                isDisabled
                                                icon={
                                                    <Center mb={0.5}>
                                                        <CryptoIcon
                                                            crypto="ETH"
                                                            size={16}
                                                        />
                                                    </Center>
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                    {status?.TRON && (
                                        <Tooltip
                                            label={
                                                'Under maintenance. Please do not deposit in your wallet. Please use USDT@NACE Pay instead.'
                                            }
                                            hasArrow
                                        >
                                            <IconButton
                                                p={0}
                                                pt={1}
                                                size="xs"
                                                variant={'unstyled'}
                                                onClick={() => {
                                                    setCryptoOnly('TRON');
                                                    setOpenHDWallet(true);
                                                }}
                                                isDisabled
                                                icon={
                                                    <Center mb={0.5}>
                                                        <CryptoIcon
                                                            crypto="TRX"
                                                            size={16}
                                                        />
                                                    </Center>
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </>
                            )}
                    </HStack>
                </MotionBox>
                {/* )} */}
            </HStack>
            <BrochureDialog
                open={open}
                onClose={() => setOpen(false)}
                profile={profile}
            />
            {kycVerified && status?.enable && (
                <>
                    <ClientHDWalletDialog
                        open={openHDWallet}
                        cryptoOnly={cryptoOnly}
                        onClose={() => setOpenHDWallet(false)}
                    />
                    {/* <HDWalletAlertDialog
                        open={showHDAlertDialog}
                        onClose={() => setShowHDAlertDialog(false)}
                    /> */}
                </>
            )}
        </>
    );
};

const Zendesk = () => {
    return (
        <Helmet>
            <script
                async
                id="ze-snippet"
                src="https://static.zdassets.com/ekr/snippet.js?key=741a5ab9-7540-4e55-8328-aec45b86b853"
            ></script>
        </Helmet>
    );
};
const NavBarQuickAccess = () => {
    const {t} = useTranslation('app');
    const {data: profile, isIB} = useProfile();
    const {data: forexData} = useForexAccountList({
        enableOnly: true,
        type: 'Live',
    });
    const depositState = useDepositStore((depositState) => depositState);
    const navigate = useNavigate();
    const [sideBarOpen] = useUIStore((state) => [state.sideBarOpen]);
    const [show, setShow] = useState(false);
    const [brochureOpen, setBrochureOpen] = useState(false);
    const [showApplyIB, setShowApplyIB] = useState(false);
    const accessList = [
        {
            icon: HiCurrencyDollar,
            label: t('deposit'),
            onClick: () => {
                depositState.reset();
                navigate(paths.newDeposit);
            },
        },
        {
            icon: HiBanknotes,
            label: t('withdraw'),
            onClick: () => navigate(paths.creatWithdrawal),
        },
        {
            icon: HiArrowRight,
            label: t('transfer'),
            onClick: () => navigate(paths.creatTransfer),
        },
        ...(isIB?[
            {
                icon: HiUserPlus,
                label: t('inviteFriend'),
                onClick: () =>
                    navigate(paths.pendingInvitations)
                        // : navigate(paths.referees),
            }
        ]:[]),
        {
            icon: HiPlus,
            label: t('applyAliveAccount'),
            onClick: () => setShow(true),
        },
        ...(isIB
            ? [
                  {
                      icon: IoDiamondSharp,
                      label: t('commission'),
                      onClick: () => navigate(paths.commissions),
                  },
              ]
            : []),
        ...(!isIB && forexData?.length
            ? [
                  {
                      icon: IoDiamondSharp,
                      label: t('applyIB'),
                      onClick: () => setShowApplyIB(true),
                  },
              ]
            : []),
    ];
    return (
        <HStack pl={14}>
            <MotionBox
                spacing={0}
                lineHeight={1.3}
                animate={
                    sideBarOpen
                        ? {
                              opacity: 1,
                              display: 'block',
                          }
                        : {
                              opacity: 0,
                              height: '0px',
                              transitionEnd: {
                                  display: 'none',
                              },
                          }
                }
                transition={'linear'}
                initial={false}
                tran
            >
                <HStack spacing={1}>
                    {accessList.map((item, index) => (
                        <Tooltip label={item.label} key={index}>
                            <IconButton
                                variant={'outline'}
                                size="xs"
                                icon={<Icon as={item.icon} boxSize={'4'} />}
                                onClick={item.onClick}
                            />
                        </Tooltip>
                    ))}
                </HStack>
            </MotionBox>
            <ApplyForexAccount isOpen={show} onClose={() => setShow(false)} />
            <InviteDialog
                open={brochureOpen}
                onClose={() => setBrochureOpen(false)}
                profile={profile}
            />
            <ApplyIB
                isOpen={showApplyIB}
                onClose={() => setShowApplyIB(false)}
            />
        </HStack>
    );
};

const TimeView = (props) => {
    const {t} = useTranslation('app');
    const [serverDate, setServerDate] = useState(null);
    const {serverDateTime} = useProfile();
    let timer = null;

    useEffect(() => {
        //serverDateTime为数据返回时间
        timer && clearInterval(timer);
        setServerDate(serverDateTime);
    }, [serverDateTime]);

    useEffect(() => {
        const d =
            typeof serverDate === 'string' ? new Date(serverDate) : serverDate;
        timer = setInterval(() => {
            if (d) {
                const next = addSeconds(d, 1);
                setServerDate(next);
            }
        }, 1000);
        return () => {
            timer && clearInterval(timer);
        };
    }, [serverDate]);

    return (
        <HStack spacing={1} alignItems={'center'}>
            <Text fontSize={'xs'}>{`${t('serverDateTime')}: ${
                serverDate ? formatDateTimezone(serverDate) : ''
            }`}</Text>
            <Tooltip label={t('UTC')} hasArrow>
                <Box
                    display={'flex'}
                    justifyContent="center"
                    alignItems={'center'}
                >
                    <Icon as={IoMdTime} boxSize={4} />
                </Box>
            </Tooltip>
        </HStack>
    );
};

const Header = () => {
    const {t} = useTranslation('app');
    const {isUser} = useAuth();
    const [headerTitle, headerDesc, showMobileDrawer, setShowMobileDrawer] =
        useUIStore((state) => [
            state.headerTitle,
            state.headerDesc,
            state.showMobileDrawer,
            state.setShowMobileDrawer,
        ]);
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });

    const grayColor = useColorModeValueKey('gray');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const defaultHeaderTitle = t(camelCase(mainPath));

    return (
        <HStack
            alignItems={'center'}
            justifyContent={'space-between'}
            px={{base: 2, md: 4}}
            py={2}
            bg={useColorModeValueKey('navBg')}
            transition={'background-color 200ms linear'}
            zIndex={2}
            shadow={'sm'}
        >
            <HStack alignItems={'center'} position="relative" flex={1}>
                {isMobile ? (
                    <HStack>
                        <IconButton
                            onClick={() => setShowMobileDrawer(true)}
                            icon={<HiOutlineMenu size={'24px'} />}
                            variant={'ghost'}
                        />
                        <Heading
                            fontWeight={'600'}
                            fontSize={'lg'}
                            textAlign="center"
                            flex={1}
                        >
                            {headerTitle ? headerTitle : defaultHeaderTitle}
                        </Heading>
                    </HStack>
                ) : (
                    <HStack spacing={2} alignItems={'flex-end'}>
                        <Heading fontWeight={'600'} fontSize={'lg'}>
                            {headerTitle ? headerTitle : defaultHeaderTitle}
                        </Heading>
                        {
                            headerDesc ? (
                                <Text color={grayColor} fontSize="xs">
                                    {headerDesc}
                                </Text>
                            ) : null
                            // <CustomBreadcrumb />
                        }
                    </HStack>
                )}
            </HStack>
            <HStack alignItems={'center'} spacing={2}>
                {!isMobile && <TimeView />}
                <NotificationBtn />
                {isUser && <PlatformCurrencyBtn />}
                {!isMobile && (
                    <HStack alignItems={'center'} spacing={2}>
                        <LanguageBtn />
                        <ColorModeBtn />
                    </HStack>
                )}
                {!isMobile && (
                    <Divider h={'36px'} orientation="vertical" pl={2} />
                )}
                <ProfileBtn />
            </HStack>
        </HStack>
    );
};

const NavBar = () => {
    const [sideBarOpen, setSideBarOpen] = useUIStore((state) => [
        state.sideBarOpen,
        state.setSideBarOpen,
    ]);
    const {isUser} = useAuth();
    const {kycVerified} = (isUser ? useProfile : useAdminProfile)();
    return (
        <MotionStack
            animate={{
                width: sideBarOpen ? '240px' : '64px',
                minWidth: sideBarOpen ? '240px' : '64px',
            }}
            initial={false}
            zIndex={3}
            shadow={'sideBar'}
            position={'relative'}
            transition={'width 200ms linear, min-width 200ms linear'}
        >
            <Stack
                bg={useColorModeValueKey('sideBarBg')}
                transition={'background-color 200ms linear'}
                spacing={4}
                flex={1}
                pt={1}
                overflow={'hidden'}
            >
                <AppLogoArea collapsed={sideBarOpen} />
                {isUser && (
                    <Stack spacing={1}>
                        <NavBarItem />
                        {kycVerified && <NavBarQuickAccess />}
                    </Stack>
                )}
                <NavDrawer />
                <IconButton
                    position={'absolute'}
                    top={0}
                    right={-3}
                    zIndex={3}
                    size="xs"
                    shadow={'lg'}
                    rounded={'full'}
                    // bg={'light.bg.50'}
                    // _hover={{
                    //     bg: 'light.bg.100',
                    // }}
                    icon={
                        <Icon
                            boxSize={'4'}
                            as={CgArrowsExchangeAlt}
                            // color={designSystemColors.light.text}
                        />
                    }
                    onClick={() => setSideBarOpen(!sideBarOpen)}
                />
            </Stack>
        </MotionStack>
    );
};

const AppLayout = () => {
    useAutoLogout();
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });
    const {isUser} = useAuth();
    const { pathname } = useLocation()
    const hasIbAgent = pathname.split('/').includes('ib-agent')
    return (
        <HStack alignItems={'stretch'} h={'100vh'} w="100vw" spacing={0}>
            {isMobile ? (
                <MobileNavDrawer />
            ) : (
                <>
                {/* <DarkMode> */}
                <NavBar />
                {/* </DarkMode> */}
                </>
            )}
            {isUser && <Zendesk />}
            <Stack
                flex={1}
                bg={useColorModeValueKey('bg')}
                transition={'background-color 200ms linear'}
                spacing={0}
                display="flex"
                w="100%"
            >
                <Header />
                <Box overflow={'scroll'} flex={1} zIndex={1} w="100%">
                    <Box
                        px={{base: 4, md: 4}}
                        pt={4}
                        pb={{base: 6, md: 4}}
                        mx="auto"
                        mb={isUser ? 20 : 0}
                        maxW={isUser ? hasIbAgent? '1500px': '1500px' : '100%'}
                        // overflow={'scroll'}
                        // h="100%"
                    >
                        <Outlet />
                    </Box>
                </Box>
            </Stack>
        </HStack>
    );
};

export const StretchAppContnetArea = (props) => {
    return (
        <Stack m={{base: -2, md: -4}} my={-2}>
            {props.children}
        </Stack>
    );
};

export default AppLayout;
