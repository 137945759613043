import { Spinner } from '@chakra-ui/react';
import { useUserPreference } from 'hooks/datahook/settings';
import { get, last } from 'lodash';
import PropTypes from 'prop-types';
import {Navigate} from 'react-router-dom';
import {authPaths} from 'routes/path';

// ----------------------------------------------------------------------

PassOtpResetRequiredGuard.propTypes = {
    children: PropTypes.node,
};

export default function PassOtpResetRequiredGuard({children}) {
    const {data: preference,isLoading} = useUserPreference();
    if(isLoading){
        return <Spinner/>
    }
    if (
        get(preference, 'requiredUpdatePassword') ||
        get(preference, 'requiredUpdateOtp2FA') ||
        get(preference, 'requiredUpdateSms2FA')
    ) {
        return <Navigate to={authPaths.requiredUpdatePassOtp} />;
    }
    return <>{children}</>;
}
